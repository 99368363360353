
const MotorhomeSVG = ({ SvgData }) => {

return (
    <div data-v-9df6b412="" class={SvgData}>
        <svg data-v-136f7dbe="" xmlns="http://www.w3.org/2000/svg" viewBox="56 77 1218 1030">
            <path data-v-136f7dbe="" xmlns="http://www.w3.org/2000/svg"
                  d="M374.6,927.8h550.4c6.9,0,12.6,5.7,12.6,12.6v131.9c0,6.9-5.7,12.6-12.6,12.6H374.6c-6.9,0-12.6-5.7-12.6-12.6  V940.4C362,933.3,367.7,927.8,374.6,927.8z"
                  class="st0"></path>
            <path data-v-136f7dbe=""
                  d="M534.1 111.27H676v198.37H378.7a43.39 43.39 0 0 1-18.42-4.09 16 16 0 0 1-9.39-14.46V111.27h101.58s-.7 3.87 1.38 22.21c1.65 14.52 15.7 20.68 21.47 22.61a14.36 14.36 0 0 0 4.42.72h29.58c11.44-2 20.45-16 20.45-16a52.31 52.31 0 0 0 4.33-29.54"
                  class="dmg__section-95 cls-1"></path>
            <path data-v-136f7dbe=""
                  d="M1038.86 476.77v72.07a20.92 20.92 0 0 0 5.69 14.38l2.78 2.93a20 20 0 0 0 14.19 6.32l12.37.17.12 17.07a6.06 6.06 0 0 0 6.06 6h43.12v-8.43a7.19 7.19 0 0 0-7.18-7.18h-32.26v5.2h-4.08v-12.47h1.87l145.68 3a19.48 19.48 0 0 0 12.5-4.23l2.37-1.88v-93.24z"
                  class="dmg__section-90 cls-2"></path>
            <path data-v-136f7dbe=""
                  d="M1240.46 473.17h-201.6v-72.06a21 21 0 0 1 5.69-14.38l2.78-2.93a20.1 20.1 0 0 1 14.19-6.32l12.37-.18.12-16.74a6.38 6.38 0 0 1 6.38-6.34h42.8v9.7a5.92 5.92 0 0 1-5.92 5.92h-33.52v-5.21h-4.08v12.48h1.87l145.68-3a19.55 19.55 0 0 1 12.5 4.24l2.37 1.87v92.92z"
                  class="dmg__section-97 cls-1"></path>
            <path data-v-136f7dbe=""
                  d="M1242.09 569.69h29.18c2.26 0 4.09-2.49 4.09-5.57v-22.44c0-3.08-1.83-5.58-4.09-5.58h-29.18zM1242.09 413.7h29.18c2.26 0 4.09-2.5 4.09-5.58v-22.44c0-3.08-1.83-5.58-4.09-5.58h-29.18z"></path>
            <path data-v-136f7dbe=""
                  d="M1038.86 516.22l-2-1.14a5.27 5.27 0 0 1-2.66-4.59v-71.72a5.9 5.9 0 0 1 2.7-4.95l2-1.26"
                  class="cls-3"></path>
            <path data-v-136f7dbe=""
                  d="M1064.51 544l80.49 22a10.66 10.66 0 0 0 10.84-10.48v-163.4a10.64 10.64 0 0 0-10.64-10.64h-.36l-80.35 21.65a7.47 7.47 0 0 0-7.2 6.86c-1.29 15.85 1.68 37.58 2 65.4.26 27.59-3.17 46.07-2 61.67a7.48 7.48 0 0 0 7.22 6.94z"
                  class="cls-4"></path>
            <path data-v-136f7dbe=""
                  d="M1141.86 566.03V381.58M1050.2 553.89v-6a2 2 0 0 1 2-2 2 2 0 0 1 2 2v6a2 2 0 0 1-2 2 2 2 0 0 1-2-2zM1050.19 402.22v-6a2 2 0 0 1 2-2 2 2 0 0 1 2 2v6a2 2 0 0 1-2 2 2 2 0 0 1-2-2zM1183.54 574.91s1.72-9.71 3.45-31.44c1.61-20.19 8-22.73 15.34-24.24a2 2 0 0 1 2.37 1.54 1.8 1.8 0 0 1 0 .46c-.65 15.23-1.5 36.09-3.55 38.3-5.07 5.46-11.71 6.47-16.42 6.47"
                  class="cls-5"></path>
            <path data-v-136f7dbe=""
                  d="M1190.16 559.35a2.79 2.79 0 0 1 2.79-2.79 2.79 2.79 0 0 1 2.79 2.79 2.79 2.79 0 0 1-2.79 2.79 2.79 2.79 0 0 1-2.79-2.79zM1186.49 549.42s6.63-16.33 18-21.58M1216.7 575.59c-.5 0-3.17-2.2-3.17-5v-94M1218 531.89v22.69a7.88 7.88 0 0 0 2.16 5.43 7.85 7.85 0 0 0 4.68 2.41c2 .27 4.54.78 5.49 1.64"
                  class="cls-5"></path>
            <path data-v-136f7dbe=""
                  d="M1222.7 553a3.57 3.57 0 0 1 3.57-3.57 3.57 3.57 0 0 1 3.57 3.57 3.57 3.57 0 0 1-3.57 3.57 3.57 3.57 0 0 1-3.57-3.57zM1183.68 374.74s1.71 9.72 3.44 31.44c1.61 20.2 8 22.73 15.34 24.25a2 2 0 0 0 2.38-1.52 2 2 0 0 0 .05-.48c-.65-15.23-1.51-36.09-3.56-38.29-5.07-5.46-11.71-6.48-16.42-6.48"
                  class="cls-5"></path>
            <path data-v-136f7dbe=""
                  d="M1195.87 390.31a2.78 2.78 0 0 1-2.79 2.79 2.78 2.78 0 0 1-2.79-2.79 2.79 2.79 0 0 1 2.79-2.79 2.79 2.79 0 0 1 2.79 2.79zM1186.62 400.24s6.63 16.32 18 21.57M1216.83 374.07c-.5 0-3.17 2.19-3.17 5v94M1218.16 417.76v-22.69a7.9 7.9 0 0 1 2.16-5.43 8.07 8.07 0 0 1 4.68-2.41c2-.27 4.54-.77 5.5-1.63"
                  class="cls-5"></path>
            <path data-v-136f7dbe=""
                  d="M1230 396.67a3.57 3.57 0 0 1-3.57 3.57 3.57 3.57 0 0 1-3.57-3.57 3.57 3.57 0 0 1 3.57-3.57 3.57 3.57 0 0 1 3.57 3.57z"
                  class="cls-5"></path>
            <path data-v-136f7dbe=""
                  d="M298.89 470.07V398a21 21 0 0 0-5.69-14.38l-2.77-2.93a20.13 20.13 0 0 0-14.19-6.31l-12.37-.18-.12-17.06a6.08 6.08 0 0 0-6.07-6h-43.11v8.43a7.17 7.17 0 0 0 7.17 7.19H254v-5.21h4.08V374h-1.87l-145.69-3A19.5 19.5 0 0 0 98 375.27l-2.36 1.88v93.21z"
                  class="dmg__section-94 cls-2"></path>
            <path data-v-136f7dbe=""
                  d="M97.3 474.67h201.59v72.06a20.92 20.92 0 0 1-5.69 14.38l-2.77 2.89a20.08 20.08 0 0 1-14.19 6.32l-12.37.18-.12 16.74a6.39 6.39 0 0 1-6.38 6.34h-42.8v-9.7a5.92 5.92 0 0 1 5.91-5.92H254v5.21h4.08v-12.44h-1.87l-145.69 3A19.54 19.54 0 0 1 98 569.47l-2.36-1.88v-92.92z"
                  class="dmg__section-93 cls-1"></path>
            <path data-v-136f7dbe=""
                  d="M298.89 427.84l2 1.14a5.32 5.32 0 0 1 2.66 4.59v71.72a5.89 5.89 0 0 1-2.7 4.95l-2 1.26"
                  class="cls-3"></path>
            <path data-v-136f7dbe=""
                  d="M95.72 377.38H66.54c-2.26 0-4.09 2.49-4.09 5.57v22.44c0 3.08 1.83 5.58 4.09 5.58h29.18zM95.72 534.37H66.54c-2.26 0-4.09 2.5-4.09 5.58v22.44c0 3.08 1.83 5.58 4.09 5.58h29.18z"></path>
            <path data-v-136f7dbe=""
                  d="M288.52 394.55v6a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2 2 2 0 0 1 2 2zM288.51 547.21v6a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2 2 2 0 0 1 2 2zM165.39 372.16s14.08 8.29 17.58 13.29H131s2.5-10.58 7.5-13.29M165.49 572.37s14.08-7.19 17.58-11.52h-52s2.73 11.1 9.5 12.52M182.97 387.45l.1 173.4M130.97 387.45l.1 173.4M116.72 444.7h5v54.5h-5M134.72 467.2h5.5v9.25h-5.5z"
                  class="cls-5"></path>
            <circle data-v-136f7dbe="" cx="115.92" cy="394.25" r="3.29" class="cls-5"></circle>
            <circle data-v-136f7dbe="" cx="115.92" cy="553.5" r="3.29" class="cls-5"></circle>
            <circle data-v-136f7dbe="" cx="142.97" cy="380.13" r="2.75" class="cls-5"></circle>
            <circle data-v-136f7dbe="" cx="151.97" cy="380.13" r="2.75" class="cls-5"></circle>
            <circle data-v-136f7dbe="" cx="142.97" cy="567.11" r="2.75" class="cls-5"></circle>
            <circle data-v-136f7dbe="" cx="151.97" cy="567.11" r="2.75" class="cls-5"></circle>
            <path data-v-136f7dbe=""
                  d="M825.59 309.64C866.68 300 956 206.84 968 194.2s5-35.86 5-35.86l5.13-.57c-.37-6.07-4.13-18.93-7.13-30.87s-26.5-9-26.5-9-75.5-3.55-89.74-3.75c1.77 31.81-27.61 42.25-27.61 42.25h-30.94s-21.33-3.75-23.93-21.5-1.82-23.62-1.82-23.62H683.1l.4 198.37h142.09"
                  class="dmg__section-96 cls-2"></path>
            <ellipse data-v-136f7dbe="" cx="493.58" cy="119.14" rx="37.44" ry="36"></ellipse>
            <ellipse data-v-136f7dbe="" cx="493.58" cy="119.55" rx="19.6" ry="18.84" class="cls-1"></ellipse>
            <ellipse data-v-136f7dbe="" cx="493.58" cy="119.55" rx="9.8" ry="9.42" class="cls-5"></ellipse>
            <ellipse data-v-136f7dbe="" cx="811.85" cy="117.48" rx="37.44" ry="36"></ellipse>
            <ellipse data-v-136f7dbe="" cx="811.85" cy="117.89" rx="19.6" ry="18.84" class="cls-2"></ellipse>
            <ellipse data-v-136f7dbe="" cx="811.85" cy="117.48" rx="9.8" ry="9.42" class="cls-5"></ellipse>
            <path data-v-136f7dbe="" d="M358.35 172.14l504.6-1.66 19.58-4.34 53.47-11" class="cls-5"></path>
            <path data-v-136f7dbe=""
                  d="M844.85 301.39s15-82.91 16.71-92.58c.69-30.33 3.12-93.67 3.12-93.67M350.89 194.2l6.63-9.88a10 10 0 0 0 1.69-5.5v-27.34a8.36 8.36 0 0 0-4.57-7.38l-3.75-2"
                  class="cls-5"></path>
            <path data-v-136f7dbe="" d="M377.93 127.23h9.01v3.42h-9.01z" class="cls-6"></path>
            <path data-v-136f7dbe=""
                  d="M798.5 574.94H356.9a12 12 0 0 1-12-12V378.06a12 12 0 0 1 12-12h601.32c10.95 0 19.91 8.4 19.91 15v178.88c0 6.6-9 15-19.91 15z"
                  class="dmg__section-98 cls-2"></path>
            <path data-v-136f7dbe=""
                  d="M540.08 401.7h234.88v7.33H540.08zM539.31 535.04h234.88v7.33H539.31zM368.24 371.57h2.67v198.8h-2.67zM943.1 219.45s-76.6.39-91.1 1.89c-10.61 1.09-19.83 35.37-24 55.85a9.16 9.16 0 0 0 10.2 10.9l26.49.34M973 158.34l-37-3.2-.29-37.66M974.13 168.17s-16.92 2.67-19.78 18.67 0 21.43 0 21.43"
                  class="cls-5"></path>
            <path data-v-136f7dbe=""
                  d="M526.35 828.42h141.9V630H371a43.54 43.54 0 0 0-18.42 4.09 16 16 0 0 0-9.4 14.47v179.86h101.54s-.7-3.88 1.38-22.21c1.65-14.52 15.7-20.68 21.47-22.61a13.78 13.78 0 0 1 4.42-.72h29.57c11.45 2 20.46 16 20.46 16a52.31 52.31 0 0 1 4.33 29.54"
                  class="dmg__section-92 cls-1"></path>
            <path data-v-136f7dbe=""
                  d="M817.84 630c41.09 9.69 130.41 102.81 142.41 115.45s5 35.86 5 35.86l5.13.57c-.37 6.06-4.13 18.93-7.13 30.87s-26.5 9-26.5 9-75.5 3.55-89.74 3.74c1.77-31.81-27.61-42.25-27.61-42.25h-30.94s-21.33 3.75-23.93 21.5-1.82 23.63-1.82 23.63h-87.36l.4-198.38h142.09"
                  class="dmg__section-91 cls-2"></path>
            <ellipse data-v-136f7dbe="" cx="485.84" cy="820.54" rx="37.44" ry="36"></ellipse>
            <ellipse data-v-136f7dbe="" cx="485.84" cy="820.13" rx="19.6" ry="18.84" class="cls-1"></ellipse>
            <ellipse data-v-136f7dbe="" cx="485.84" cy="820.13" rx="9.8" ry="9.42" class="cls-5"></ellipse>
            <ellipse data-v-136f7dbe="" cx="804.1" cy="822.21" rx="37.44" ry="36"></ellipse>
            <ellipse data-v-136f7dbe="" cx="804.1" cy="821.8" rx="19.6" ry="18.84" class="cls-2"></ellipse>
            <ellipse data-v-136f7dbe="" cx="804.1" cy="822.21" rx="9.8" ry="9.42" class="cls-5"></ellipse>
            <path data-v-136f7dbe="" d="M350.6 767.54l504.6 1.67 19.58 4.33 53.47 11" class="cls-5"></path>
            <path data-v-136f7dbe=""
                  d="M837.1 638.29s15 82.92 16.71 92.59c.69 30.33 3.12 93.66 3.12 93.66M343.13 745.49l6.64 9.87a10 10 0 0 1 1.69 5.51v27.34a8.37 8.37 0 0 1-4.58 7.38l-3.75 1.95"
                  class="cls-5"></path>
            <path data-v-136f7dbe="" d="M370.18 809.04h9.01v3.42h-9.01z" class="cls-6"></path>
            <path data-v-136f7dbe=""
                  d="M935.35 720.23s-76.6-.38-91.1-1.88c-10.61-1.1-19.83-35.38-24-55.86a9.14 9.14 0 0 1 10.2-10.9l26.49-.33M965.25 781.35l-37 3.19-.29 37.67M966.38 771.51s-16.92-2.66-19.78-18.66 0-21.43 0-21.43"
                  class="cls-5"></path>
            <path data-v-136f7dbe=""
                  d="M827 404.61v136.66a10 10 0 0 0 9.61 10l96 4.14a10 10 0 0 0 10.46-9.52V397.49A10.12 10.12 0 0 0 933 387.38h-.74l-95.88 7.13a10.12 10.12 0 0 0-9.38 10.1z"
                  class="cls-5"></path>
            <path data-v-136f7dbe=""
                  d="M356.6,917.3h598.1c7.5,0,18.7,6.2,18.7,14V1078c0,7.8-11.1,14-18.7,14H356.6c-7.5,0-13.7-6.2-13.7-14V931.3 C343,923.6,349.1,917.3,356.6,917.3z"
                  class="st0 dmg__section-99"></path>
            <path data-v-136f7dbe=""
                  d="M458.4,934.4h60.5c1.8,0,3.2,1.4,3.2,3.2v13.3c0,1.8-1.4,3.2-3.2,3.2h-60.5c-1.8,0-3.2-1.4-3.2-3.2v-13.3 C455.2,935.8,456.6,934.4,458.4,934.4z"></path>
            <path data-v-136f7dbe=""
                  d="M458.4,957.1h60.5c1.8,0,3.2,1.4,3.2,3.2v13.3c0,1.8-1.4,3.2-3.2,3.2h-60.5c-1.8,0-3.2-1.4-3.2-3.2v-13.3 C455.2,958.6,456.6,957.1,458.4,957.1z"></path>
            <path data-v-136f7dbe=""
                  d="M458.4,1033.5h60.5c1.8,0,3.2,1.4,3.2,3.2v13.3c0,1.8-1.4,3.2-3.2,3.2h-60.5c-1.8,0-3.2-1.4-3.2-3.2v-13.3 C455.2,1034.9,456.6,1033.5,458.4,1033.5z"></path>
            <path data-v-136f7dbe=""
                  d="M458.4,1056.2h60.5c1.8,0,3.2,1.4,3.2,3.2v13.3c0,1.8-1.4,3.2-3.2,3.2h-60.5c-1.8,0-3.2-1.4-3.2-3.2v-13.3 C455.2,1057.7,456.6,1056.2,458.4,1056.2z"></path>
            <path data-v-136f7dbe=""
                  d="M780.7,933h60.5c1.8,0,3.2,1.4,3.2,3.2v13.3c0,1.8-1.4,3.2-3.2,3.2h-60.5c-1.8,0-3.2-1.4-3.2-3.2v-13.3 C777.5,934.4,778.9,933,780.7,933z"></path>
            <path data-v-136f7dbe=""
                  d="M782,1056.8h60.5c1.8,0,3.2,1.4,3.2,3.2v13.3c0,1.8-1.4,3.2-3.2,3.2H782c-1.8,0-3.2-1.4-3.2-3.2V1060 C778.8,1058.3,780.2,1056.8,782,1056.8z"></path>
            <line data-v-136f7dbe="" x1="806.2" y1="950" x2="804.9" y2="1060.7" class="st2"></line>
            <line data-v-136f7dbe="" x1="816.2" y1="950" x2="815" y2="1060.7" class="st2"></line>
            <rect data-v-136f7dbe="" x="370.5" y="991.7" width="75.9" height="27.4" class="st2"></rect>
            <rect data-v-136f7dbe="" x="447.1" y="997.3" width="21.2" height="13.2" class="st2"></rect>
            <line data-v-136f7dbe="" x1="467.9" y1="1001.8" x2="476.2" y2="1001.8" class="st2"></line>
            <line data-v-136f7dbe="" x1="467.9" y1="1005.7" x2="476.2" y2="1005.7" class="st2"></line>
            <line data-v-136f7dbe="" x1="476.2" y1="976.9" x2="476.2" y2="1033.5" class="st2"></line>
            <line data-v-136f7dbe="" x1="494.2" y1="974.7" x2="494.2" y2="1033.5" class="st2"></line>
            <path data-v-136f7dbe=""
                  d="M382.7,1054.5l19-19c1.7-1.7,4.3-1.7,6,0l0.9,0.9c1.7,1.7,1.7,4.3,0,6l-19,19c-1.7,1.7-4.3,1.7-6,0l-0.9-0.9 C381,1058.8,381,1056.1,382.7,1054.5z"
                  class="st4"></path>
            <path data-v-136f7dbe="" d="M409.3,1034.9l3.7-3.8c1.5-1.5,3.5-2.3,5.5-2.3h33.8c2.2,0,4-1.8,4-4l0,0l0,0V1012"
                  class="st7"></path>
            <path data-v-136f7dbe="" d="M374.6,1071c0.1-0.2,2.6-2.7,8.7-8.8" class="st7"></path>
        </svg>
    </div>
  )
}

export default MotorhomeSVG
