const LorrySVG = ({ SvgData }) => {

return (
        <div data-v-03070902="" className={SvgData}>
            <svg data-v-f2009c9c="" xmlns="http://www.w3.org/2000/svg" viewBox="56 56 1302 1200">
                <path data-v-f2009c9c=""
                      d="M833.3,1187.5h-14.2c0,0,0.5,46.4,0,44c-0.6-3-380.1,0-380.1,0v-190h381.2v21c0,0,6.3,6.2,11.2,18h2V1049h108  c0,0,14.8,3.4,24.9,6.2c5.2,1.5,9.2,5.6,10.2,10.8c7.3,35.8,5.8,95.9-0.9,143.7c-0.6,4.7-3.8,8.6-8.3,10.4  c-9.7,3.6-19.5,6.6-29.9,8.8H833.1L833.3,1187.5z"
                      class="dmg__section-80 cls-1 st0"></path>
                <path data-v-f2009c9c=""
                      d="M1119,522.39h-73.3V628h212.8V617.2l-58.38,1.24a15.07,15.07,0,0,1-2.12-.07c-7.81-.73-56.48-5.24-56.22-5.31s-6.89-.31-12-1.91a8.06,8.06,0,0,1-1.66-.77,15,15,0,0,1-7.1-10.6,81.3,81.3,0,0,1-1-8.53Z"
                      class="dmg__section-61 cls-1"></path>
                <path data-v-f2009c9c=""
                      d="M1119,518.22h-73.35V412.57h212.8V423.4l-58.39-1.22a14.89,14.89,0,0,0-2.11.08c-7.81.72-56.49,5.23-56.23,5.31s-6.89.3-12,1.91a7,7,0,0,0-1.66.77,15,15,0,0,0-7.06,10.59c-.56,3.44-1,7.31-1,8.53Z"
                      class="dmg__section-77 cls-2"></path>
                <path data-v-f2009c9c=""
                      d="M613.25,95.77s.49-9.29-8.82-18.74c-20.08-17.92-38.3-13.92-52.43-4.57-12,8-11.68,23.32-11.68,23.32L538,101.84H523.69v6.6s-47.33-1-46.33,0,1.33,25.16,0,25.16h-6.67v-6.49H451.35v16.73H819l.33,4.88,10.33-.08v-15h-7V108.82h-16v-6.94H627.87V95.82h-7.52Z"
                      class="dmg__section-74 cls-3"></path>
                <rect data-v-f2009c9c="" x="439.74" y="410.61" width="379.32" height="215.33"
                      class="dmg__section-79 cls-1 cls-13"></rect>
                <path data-v-f2009c9c=""
                      d="M961.31,615.43c21.75-1.25,24.25-41,24.25-41s1.5-53,0-105.49c-1.75-47-19.2-48.89-23.53-48.62-1,.06-2.1.12-3.1.12H836.06l-.27.08a7.76,7.76,0,0,0-5.28,6.35c-10.33,76.09-2.58,158-.13,180.08a11.11,11.11,0,0,0,5.68,8.48h0S934.81,616.18,961.31,615.43Z"
                      class="dmg__section-78 cls-4"></path>
                <path data-v-f2009c9c=""
                      d="M966.33,607.86c13.13-10.39,12.72-37.12,13.33-86.92.51-41.75-4.57-74.39-10.08-85.67a13.41,13.41,0,0,0-13.83-6.86l-19,3.71h0a2.86,2.86,0,0,0-2.22,3.38c1.46,7,4.84,27.8,5.84,73.43,1.09,48.56-4.13,81.61-5.91,91.44a3.35,3.35,0,0,0,2.45,3.85l20.42,5.41A11.69,11.69,0,0,0,966.33,607.86Z"
                      class="cls-5"></path>
                <path data-v-f2009c9c="" d="M832.22,611.92c-.84-6.73,3.09-8.49,3.09-8.49l100.27.08" class="cls-5"></path>
                <path data-v-f2009c9c="" d="M835.31,603.43s-10.46-101,1.27-172.82" class="cls-5"></path>
                <path data-v-f2009c9c="" d="M831.37,424.22a.46.46,0,0,1,.07.16c.86,6.56,5.65,6.58,10,6.63l95.29,1.11"
                      class="cls-5"></path>
                <path data-v-f2009c9c="" d="M846,615.76c-1.87-3.85,2.76-5.85,2.76-5.85s75.54-1.49,80.79,0,12.75,5.78,13.5,5.85"
                      class="cls-5"></path>
                <path data-v-f2009c9c="" d="M845.6,420.3c-1.87,3.84,2.75,5.84,2.75,5.84s75.55,1.48,80.8,0,12.75-5.77,13.5-5.84"
                      class="cls-5"></path>
                <rect data-v-f2009c9c="" x="920.8" y="413.11" width="33.75" height="7.19" class="dmg__section-78 cls-6"></rect>
                <path data-v-f2009c9c=""
                      d="M937.44,405.19l-30.72,19.7a1.67,1.67,0,0,1-.85.23h-4.62a.62.62,0,0,1-.52-.3.6.6,0,0,1,.24-.8h0l36.51-22.1"
                      class="dmg__section-78 cls-6"></path>
                <path data-v-f2009c9c="" d="M945.73,404.89v-8.16l-6.42-8.25-1.92,1.33s.17,25.33,0,25.58Z"
                      class="dmg__section-78 cls-6"></path>
                <rect data-v-f2009c9c="" x="920.81" y="615.8" width="33.75" height="7.19" class="dmg__section-78 cls-6"></rect>
                <path data-v-f2009c9c=""
                      d="M937.44,630.91l-30.72-19.7a1.68,1.68,0,0,0-.85-.24h-4.62a.62.62,0,0,0-.52.3.6.6,0,0,0,.21.81h0l36.51,22.11"
                      class="dmg__section-78 cls-6"></path>
                <path data-v-f2009c9c="" d="M945.73,631.21v8.17l-6.42,8.28-1.92-1.34s.17-25.33,0-25.58Z"
                      class="dmg__section-78 cls-6"></path>
                <line data-v-f2009c9c="" x1="819.06" y1="602.47" x2="829.9" y2="602.47" class="cls-5"></line>
                <line data-v-f2009c9c="" x1="819.06" y1="434.19" x2="829.9" y2="434.19" class="cls-5"></line>
                <path data-v-f2009c9c=""
                      d="M1291.44,427.21h49.4a3.26,3.26,0,0,1,3.26,3.24h0v18a3.26,3.26,0,0,1-3.26,3.26h-32.65V437.24l-2.69-3.54-5.56-3.66Z"
                      class="cls-0"></path>
                <path data-v-f2009c9c=""
                      d="M1291.44,613.84h49.4a3.26,3.26,0,0,0,3.26-3.26h0V592.64a3.26,3.26,0,0,0-3.26-3.26h-32.65V603.8l-2.69,3.53-5.56,3.71Z"
                      class="cls-0"></path>
                <path data-v-f2009c9c=""
                      d="M1303.35,522.39v51.5s4.5,4,4.84,5.49v21.89a8.17,8.17,0,0,1-3.77,6.89c-3,1.89-8,4.3-16.62,6.36a19.65,19.65,0,0,1-4.52.58h-3.68a7.61,7.61,0,0,1-3.34-.81c-1.24-.61-2.94-1.35-3.74-1.25-1.33.16-62,2.33-73.33,1.33-10.5,0-61.67-6.33-66.17-7.5-3.5-.67-6.5-4.5-7.67-8.67s-2.33-33.49-2.5-75.82Z"
                      class="dmg__section-60 cls-3"></path>
                <path data-v-f2009c9c=""
                      d="M1303.35,518.37V466.88s4.5-4,4.84-5.5V439.45a8.14,8.14,0,0,0-3.77-6.88c-3-1.89-8-4.3-16.62-6.36a19.65,19.65,0,0,0-4.52-.58h-3.68a7.61,7.61,0,0,0-3.34.81c-1.24.61-2.94,1.35-3.74,1.25-1.33-.16-62-2.33-73.33-1.33-10.5,0-61.67,6.33-66.17,7.5-3.5.67-6.5,4.5-7.67,8.67s-2.33,33.5-2.5,75.82Z"
                      class="dmg__section-76 cls-7"></path>
                <path data-v-f2009c9c=""
                      d="M1153.78,644.88h30a2.84,2.84,0,0,0,2.13-.93h0a7.1,7.1,0,0,0,1.82-3.48c.66-3.14,1.49-9.33-.51-14.76h0a5.65,5.65,0,0,0-4.68-2.5h-30.16a9.6,9.6,0,0,0-4.38,6.43,27.35,27.35,0,0,0,.33,10.74,15.16,15.16,0,0,0,1.74,2.79A4.69,4.69,0,0,0,1153.78,644.88Z"
                      class="dmg__section-60 cls-3"></path>
                <path data-v-f2009c9c="" d="M1146.85,634H1139a2.21,2.21,0,0,1-2.2-2.18c-.08-6.34-.26-24.36,0-24.31"
                      class="cls-8"></path>
                <path data-v-f2009c9c=""
                      d="M1188.45,637H1192a4,4,0,0,0,3.07-1.43l11.29-12.91h11.54a1.71,1.71,0,0,0,1.74-1.61l.21-3"
                      class="cls-8"></path>
                <rect data-v-f2009c9c="" x="1219.85" y="614.36" width="11" height="8.18" class="cls-8"></rect>
                <path data-v-f2009c9c=""
                      d="M1157,634.3h0a2.25,2.25,0,0,1,2.25-2.25h17.85a2.25,2.25,0,0,1,2.25,2.25h0a2.23,2.23,0,0,1-2.23,2.25h-17.85a2.24,2.24,0,0,1-2.27-2.23Z"
                      class="cls-5"></path>
                <path data-v-f2009c9c=""
                      d="M1154.2,395.57h30a2.83,2.83,0,0,1,2.12.92h0a7.1,7.1,0,0,1,1.82,3.48c.66,3.15,1.5,9.33-.51,14.76h0a5.61,5.61,0,0,1-4.67,2.5H1152.8a9.53,9.53,0,0,1-4.33-6.43,27.3,27.3,0,0,1,.33-10.73,14.83,14.83,0,0,1,1.74-2.79A4.68,4.68,0,0,1,1154.2,395.57Z"
                      class="dmg__section-76 cls-7"></path>
                <path data-v-f2009c9c="" d="M1147.27,406.4h-7.83a2.19,2.19,0,0,0-2.2,2.17c-.08,6.34-.26,24.36,0,24.32"
                      class="cls-8"></path>
                <path data-v-f2009c9c=""
                      d="M1188.86,402.4h3.54a4,4,0,0,1,3.07,1.42l11.3,12.91h11.54a1.73,1.73,0,0,1,1.69,1.62l.22,3"
                      class="cls-8"></path>
                <rect data-v-f2009c9c="" x="1220.26" y="417.89" width="11" height="8.18" class="cls-8"></rect>
                <path data-v-f2009c9c=""
                      d="M1179.77,406.15h0a2.25,2.25,0,0,1-2.25,2.25h-17.83a2.25,2.25,0,0,1-2.25-2.25h0a2.25,2.25,0,0,1,2.25-2.25h17.83a2.23,2.23,0,0,1,2.25,2.23Z"
                      class="cls-5"></path>
                <path data-v-f2009c9c=""
                      d="M1145.92,604.9l48.64,4.69a6.27,6.27,0,0,0,6.86-5.64v-.23c1.5-25.12,6.25-116.82-1.18-164.71a7,7,0,0,0-7.23-5.87c-15.88.75-36.5,2.47-46,3.29a6.62,6.62,0,0,0-6,5.71c-2.84,20.77-12.1,99,.36,158.68A5.13,5.13,0,0,0,1145.92,604.9Z"
                      class="cls-5"></path>
                <path data-v-f2009c9c="" d="M1215.52,614.36s7.83-23.68,7.5-93-8.17-95.23-8.17-95.23" class="cls-5"></path>
                <path data-v-f2009c9c="" d="M1225.06,614.36s7.84-23.68,7.5-93-8.17-95.24-8.17-95.24" class="cls-5"></path>
                <line data-v-f2009c9c="" x1="1220.56" y1="582.72" x2="1230.62" y2="577.33" class="cls-5"></line>
                <path data-v-f2009c9c="" d="M1220,457.9c0,.53,10.12,6,10.12,6" class="cls-5"></path>
                <path data-v-f2009c9c=""
                      d="M1267.74,613.26s5.24-1.13,5.87-6.22,0-51.11,0-50.74-14.5,4.14-17.25,16.14c-2.5,10.11-6.37,32.29-5.87,41.45"
                      class="cls-5"></path>
                <path data-v-f2009c9c="" d="M1251.7,597.55c-.85.08-23.28,0-23.28,0" class="cls-5"></path>
                <path data-v-f2009c9c=""
                      d="M1257.54,565.36a28.19,28.19,0,0,1,16.34-10.85c.1-11.62,0-68.61,0-68.61s-10.59-2.8-15.38-8.63a1.18,1.18,0,0,0-1.67-.23h0a1.24,1.24,0,0,0-.46.9c-.29,5.9-1.41,19.56-1.57,44.45-.18,28.36.67,37.83,1.24,42.56a.85.85,0,0,0,1,.73.82.82,0,0,0,.55-.33Z"
                      class="cls-9"></path>
                <line data-v-f2009c9c="" x1="1257.54" y1="565.36" x2="1258.5" y2="477.27" class="cls-5"></line>
                <line data-v-f2009c9c="" x1="1261.23" y1="561.24" x2="1262.11" y2="480.52" class="cls-5"></line>
                <line data-v-f2009c9c="" x1="1264.92" y1="558.33" x2="1265.75" y2="482.7" class="cls-5"></line>
                <line data-v-f2009c9c="" x1="1269.58" y1="555.86" x2="1270.06" y2="484.6" class="cls-5"></line>
                <path data-v-f2009c9c=""
                      d="M1267.8,427s5.24,1.13,5.86,6.25,0,51.13,0,50.75-14.5-4.12-17.25-16.12c-2.5-10.13-6.38-32.3-5.88-41.46"
                      class="cls-5"></path>
                <path data-v-f2009c9c="" d="M1251.76,442.71c-.85-.08-23.28,0-23.28,0" class="cls-5"></path>
                <path data-v-f2009c9c=""
                      d="M1272.61,427.21s4.33.92,4.71,6.67-.06,89-.06,89,.37,77.74,0,83.49-4.74,6.68-4.74,6.68"
                      class="cls-5"></path>
                <rect data-v-f2009c9c="" x="1286.36" y="575.75" width="8.62" height="14.88" rx="2.57" ry="2.57"
                      class="cls-5"></rect>
                <path data-v-f2009c9c=""
                      d="M1279.61,425.64s3.38,2.36,4.13,8.86,0,83.87,0,83.87v4s.75,77.36,0,83.86-4.13,8.88-4.13,8.88"
                      class="cls-9"></path>
                <polyline data-v-f2009c9c="" points="1298.81 429.73 1298.81 517.87 1298.84 522.89 1298.84 611.02"
                          class="cls-5"></polyline>
                <rect data-v-f2009c9c="" x="1286.34" y="450.12" width="8.62" height="14.88" rx="2.57" ry="2.57"
                      class="cls-5"></rect>
                <path data-v-f2009c9c=""
                      d="M1296.24,518.37V477a2.17,2.17,0,0,0-1.48-2.08l-5.2-1.84a2.19,2.19,0,0,0-2.84,1.25v0a2.2,2.2,0,0,0-.15.81v90.5a2.2,2.2,0,0,0,2.19,2.23h0a2.23,2.23,0,0,0,.81-.14l5.2-1.84a2.21,2.21,0,0,0,1.47-2.09V518.37Z"
                      class="cls-5"></path>
                <line data-v-f2009c9c="" x1="1320.69" y1="590.63" x2="1320.69" y2="449.9" class="cls-8"></line>
                <path data-v-f2009c9c="" d="M210.51,411.12V518.45H380.94s.66-107.33,0-107.33Z"
                      class="dmg__section-71 cls-2"></path>
                <path data-v-f2009c9c="" d="M210.51,628.8V521.45H380.94s.66,107.32,0,107.32Z"
                      class="dmg__section-66 cls-7"></path>
                <polygon data-v-f2009c9c="" points="207.44 415.27 171.84 415.45 171.85 518.12 207.44 518.45 207.44 415.27"
                         class="dmg__section-70 cls-3"></polygon>
                <polygon data-v-f2009c9c="" points="207.44 521.46 171.85 521.62 171.85 626.27 207.44 626.27 207.44 521.46"
                         class="dmg__section-67 cls-1"></polygon>
                <line data-v-f2009c9c="" x1="180.44" y1="415.42" x2="180.44" y2="626.27" class="cls-5"></line>
                <polyline data-v-f2009c9c="" points="200.26 415.27 200.26 434.04 194.86 434.04 194.86 415.42"
                          class="cls-5"></polyline>
                <polyline data-v-f2009c9c="" points="200.26 626.37 200.26 607.6 194.86 607.6 194.86 626.22"
                          class="cls-5"></polyline>
                <polyline data-v-f2009c9c="" points="168.84 407.04 148.77 407.04 148.77 635.77 169.59 635.77"
                          class="cls-5"></polyline>
                <rect data-v-f2009c9c="" x="153.65" y="492.54" width="11.54" height="53.5" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="152.65" y="421.54" width="11.54" height="34.5" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="152.65" y="584.27" width="11.54" height="34.5" class="cls-5"></rect>
                <path data-v-f2009c9c="" d="M112.4,619.77v5.75h36.33V635.8h19.82l0-113.9H112.69v49.37Z"
                      class="dmg__section-68 cls-10"></path>
                <path data-v-f2009c9c=""
                      d="M112,571.28H88.31a3.12,3.12,0,0,0-3.37,2.78h0v20.22l2.73,2L84.94,598v18.54c0,1.77,1.73,3.21,3.88,3.21h23.34Z"
                      class="cls-0"></path>
                <path data-v-f2009c9c="" d="M112.91,469.33V518.7h55.82l0-113.9H149v10.28H112.62v5.75Z"
                      class="dmg__section-69 cls-10"></path>
                <path data-v-f2009c9c=""
                      d="M112.38,420.83H89c-2.15,0-3.88,1.44-3.88,3.21v18.54l2.73,1.75-2.73,2v20.22h0a3.12,3.12,0,0,0,3.37,2.78h23.69Z"
                      class="cls-0"></path>
                <rect data-v-f2009c9c="" x="124.44" y="420.04" width="12.23" height="50.5" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="127.07" y="571.27" width="12.23" height="50.5" class="cls-5"></rect>
                <path data-v-f2009c9c="" d="M148.31,470.54H112.94" class="cls-5"></path>
                <line data-v-f2009c9c="" x1="148.77" y1="571.28" x2="112.69" y2="571.27" class="cls-5"></line>
                <rect data-v-f2009c9c="" x="224.27" y="495.65" width="33.33" height="16.47" rx="2.97" ry="2.97"
                      class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="224.27" y="527.97" width="33.33" height="16.47" rx="2.97" ry="2.97"
                      class="cls-5"></rect>
                <circle data-v-f2009c9c="" cx="871.89" cy="102.75" r="37.85"></circle>
                <circle data-v-f2009c9c="" cx="872.02" cy="102.02" r="27.45" class="cls-4"></circle>
                <circle data-v-f2009c9c="" cx="872.02" cy="101.88" r="11.28" class="cls-8"></circle>
                <circle data-v-f2009c9c="" cx="575.88" cy="101.91" r="37.85"
                        transform="translate(-2.26 13.7) rotate(-1.36)"></circle>
                <circle data-v-f2009c9c="" cx="575.89" cy="102.02" r="27.45" class="cls-4"></circle>
                <circle data-v-f2009c9c="" cx="575.97" cy="101.89" r="11.28" class="cls-4"></circle>
                <path data-v-f2009c9c=""
                      d="M945,102l-.33,11.16H950V113a6.28,6.28,0,0,1,2.6-5.09h0a12.46,12.46,0,0,1,7.59-2.37l6.59.14a10.09,10.09,0,0,1,7.82,4l.06.1v15.62a7.2,7.2,0,0,1-3.43,6.11l-.91.56,1.67,3.37v50.36c-3,26.33-36.67,82-41,90-3.52,6.5-12.55,6.83-15.81,6.73h-3.33c-10.89.14-60.47.72-79.71.21a9.25,9.25,0,0,1-8.53-6.41.62.62,0,0,0-.07-.23,59,59,0,0,1-2.54-17.4v-68.6a26.11,26.11,0,0,1,3.66-5c-.87-7.89.55-19.57,1.63-26.57.53-3.51-6.44-6.1-2.89-6.1h18.93a67.71,67.71,0,0,0,0-9.66c-11.49-8.1-14.21-22-14.84-29.1v-.38a4.18,4.18,0,0,1,4.17-4.19h0s-1.35,37.05,38,36.71,40.08-31,40.08-31h4.92V102l29.21-.13A4.64,4.64,0,0,1,945,102Z"
                      class="dmg__section-75 cls-4"></path>
                <path data-v-f2009c9c="" d="M824.8,170.83s28.87,0,49.2-2.18,76-32.83,76-32.83" class="cls-5"></path>
                <path data-v-f2009c9c="" d="M824.8,168.65s28.87,0,49.2-2.18,76-32.83,76-32.83" class="cls-5"></path>
                <path data-v-f2009c9c="" d="M950,156.11c8.66,1,22-5,22-5" class="cls-5"></path>
                <path data-v-f2009c9c="" d="M824.8,166.53s28.87,0,49.2-2.18,76-32.84,76-32.84c8-1.92,20.33.6,20.33.6"
                      class="cls-5"></path>
                <path data-v-f2009c9c="" d="M912,125.44h38c8.66-3.37,24.66,0,24.66,0" class="cls-5"></path>
                <path data-v-f2009c9c=""
                      d="M950,113.17v76.67a13.43,13.43,0,0,1-1,5l-24.76,61.4a19.45,19.45,0,0,1-10.08,10.47h-.1A12.29,12.29,0,0,1,909,267.8H850a19.4,19.4,0,0,1-13.64-5.65h0a8.07,8.07,0,0,1-2.33-5.63v-81a13.88,13.88,0,0,1,4.21-12.41l8.43-11,33-.63s29.34-3.66,35-36.66l30-1.65"
                      class="cls-9"></path>
                <line data-v-f2009c9c="" x1="914.66" y1="106.82" x2="944.85" y2="106.82" class="cls-5"></line>
                <path data-v-f2009c9c=""
                      d="M970.33,112v7a1.17,1.17,0,0,1-1.16,1.17h0A1.18,1.18,0,0,1,968,119h0v-7a1.17,1.17,0,0,1,1.17-1.17h0a1.15,1.15,0,0,1,1.17,1.15Z"
                      class="cls-5"></path>
                <path data-v-f2009c9c="" d="M843,218.11c4.67-3,107-38.29,107-38.29" class="cls-5"></path>
                <path data-v-f2009c9c=""
                      d="M936.36,191.73a5,5,0,0,1,2.74,6.52h0l-20.6,53.32A15.51,15.51,0,0,1,904,261.49H850.76a7.79,7.79,0,0,1-7.77-7.78V228.14a7.91,7.91,0,0,1,5.34-7.48l84.42-29A5,5,0,0,1,936.36,191.73Z"
                      class="cls-5"></path>
                <polygon data-v-f2009c9c="" points="856 184.15 838.66 184.15 838.66 175.81 860.74 175.81 856 184.15"
                         class="cls-5"></polygon>
                <rect data-v-f2009c9c="" x="914.66" y="174.48" width="7" height="12" class="cls-5"></rect>
                <path data-v-f2009c9c=""
                      d="M931,275.82l-3.15-3.6a9.17,9.17,0,0,1-1.47-9.75l23.3-52.15a11.32,11.32,0,0,1,7.8-6.41c4.76-1.09,11-2.61,11.68-3.09"
                      class="cls-5"></path>
                <path data-v-f2009c9c=""
                      d="M824.66,185.15l1.67,9s-.33,64.33,0,70.67a26,26,0,0,1,.12,3.06,9.85,9.85,0,0,0,8.75,9.57c18.75,2,70.33-.51,76.8-1.63,7.66-1.35,14.38-13.35,14.38-13.35"
                      class="cls-5"></path>
                <path data-v-f2009c9c=""
                      d="M929.53,259.44c-.73.39-.79-26.12-1.31-37a2.12,2.12,0,0,1,3.35-1.86l5.1,3.53.56,23.29C937.27,249,935,256.11,929.53,259.44Z"
                      class="cls-5"></path>
                <path data-v-f2009c9c=""
                      d="M918.11,174.27l1.25,23.93a5.9,5.9,0,0,0,2.38,4.16l12.57,9.12a3.79,3.79,0,0,1,1.36,4.35L933.56,222"
                      class="cls-5"></path>
                <path data-v-f2009c9c="" d="M950,182.65c6.83-1.25,22-8.38,22-8.38" class="cls-5"></path>
                <path data-v-f2009c9c="" d="M950,189.82c6.83,1.9,22-4,22-4" class="cls-5"></path>
                <rect data-v-f2009c9c="" x="440.02" y="204.94" width="375.7" height="156.88"
                      class="dmg__section-72 cls-1"></rect>
                <rect data-v-f2009c9c="" x="440.02" y="146.69" width="375.7" height="53.9" class="dmg__section-73 cls-2"></rect>
                <line data-v-f2009c9c="" x1="642.68" y1="148.62" x2="642.68" y2="200.59" class="cls-5"></line>
                <line data-v-f2009c9c="" x1="636.68" y1="148.62" x2="636.68" y2="200.59" class="cls-5"></line>
                <line data-v-f2009c9c="" x1="440.02" y1="172.15" x2="636.93" y2="172.15" class="cls-5"></line>
                <line data-v-f2009c9c="" x1="642.93" y1="172.15" x2="815.71" y2="172.15" class="cls-5"></line>
                <rect data-v-f2009c9c="" x="793.41" y="146.69" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="770.42" y="146.69" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="740.17" y="146.69" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="712.92" y="146.69" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="686.3" y="146.69" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="586.18" y="146.69" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="563.18" y="146.69" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="532.93" y="146.69" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="505.68" y="146.69" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="479.06" y="146.69" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="776.34" y="118.48" width="5.33" height="15.15" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="657.68" y="118.48" width="5.67" height="15.15" class="cls-5"></rect>
                <path data-v-f2009c9c=""
                      d="M587.25,143.82c31.24-7.34,32.77-41.94,32.77-41.94h7.85c-3.85,23.56-13.18,33.94-27.52,41.94"
                      class="cls-5"></path>
                <path data-v-f2009c9c="" d="M554.4,143.82c-29-12.34-25.33-41.94-25.33-41.94l6,.14c2,37.2,35.62,41.8,35.62,41.8"
                      class="cls-5"></path>
                <path data-v-f2009c9c="" d="M477.35,133.64s62.07-.13,62.19,0" class="cls-5"></path>
                <line data-v-f2009c9c="" x1="470.68" y1="133.64" x2="471.35" y2="143.82" class="cls-5"></line>
                <rect data-v-f2009c9c="" x="626.98" y="106.62" width="179.37" height="11.86" class="cls-5"></rect>
                <line data-v-f2009c9c="" x1="613.74" y1="133.64" x2="822.66" y2="133.64" class="cls-5"></line>
                <rect data-v-f2009c9c="" x="528.86" y="79.82" width="4.07" height="21.94" class="cls-5"></rect>
                <path data-v-f2009c9c=""
                      d="M612.25,948.55s.5,9.29-8.82,18.74c-20.08,17.88-38.3,13.89-52.39,4.53-12-8-11.69-23.31-11.69-23.31L537,942.44H522.69v-6.6s-47.34,1-46.34,0,1.34-25.15,0-25.15h-6.66v6.48H450.35V900.51H818l.33-4.88,10.34.07v15h-7v24.82h-16v6.93H626.88v6.07h-7.53Z"
                      class="dmg__section-63 cls-3"></path>
                <circle data-v-f2009c9c="" cx="870.89" cy="941.58" r="37.85"></circle>
                <circle data-v-f2009c9c="" cx="871.02" cy="942.31" r="27.45" class="cls-4"></circle>
                <circle data-v-f2009c9c="" cx="871.02" cy="942.45" r="11.28" class="cls-8"></circle>
                <circle data-v-f2009c9c="" cx="574.89" cy="942.45" r="37.85"></circle>
                <circle data-v-f2009c9c="" cx="574.89" cy="942.31" r="27.45" class="cls-4"></circle>
                <circle data-v-f2009c9c="" cx="574.97" cy="942.45" r="11.28" class="cls-8"></circle>
                <path data-v-f2009c9c=""
                      d="M944,942.31l-.33-11.07H949v.15a6.31,6.31,0,0,0,2.6,5.09h0a12.52,12.52,0,0,0,7.59,2.36l6.59-.14a10,10,0,0,0,7.82-4l.07-.09V919a7.22,7.22,0,0,0-3.43-6.15l-.91-.56L971,909V858.61c-3-26.34-36.67-82-41-90-3.52-6.5-12.54-6.84-15.8-6.74h-3.34c-10.88-.13-60.47-.72-79.7-.2a9.23,9.23,0,0,0-8.53,6.4c0,.08,0,.16-.07.24A58.52,58.52,0,0,0,820,785.59v68.59a26.59,26.59,0,0,0,3.67,5c-.88,7.89.54,19.57,1.62,26.57.54,3.51-6.43,6.1-2.88,6.1h18.92a67.85,67.85,0,0,1,0,9.67c-11.49,8.14-14.21,22-14.83,29.09v.4a4.18,4.18,0,0,0,4.19,4.17h0s-1.33-37,38-36.66,40.07,31,40.07,31h4.93v12.79l29.21.14A5.81,5.81,0,0,0,944,942.31Z"
                      class="dmg__section-62 cls-4"></path>
                <path data-v-f2009c9c="" d="M823.81,873.51s28.86,0,49.19,2.17,76,32.84,76,32.84" class="cls-5"></path>
                <path data-v-f2009c9c="" d="M823.81,875.68s28.86,0,49.19,2.18,76,32.84,76,32.84" class="cls-5"></path>
                <path data-v-f2009c9c="" d="M949,888.19c8.67-1,22,5,22,5" class="cls-5"></path>
                <path data-v-f2009c9c="" d="M823.81,877.77s28.86,0,49.19,2.18,76,32.83,76,32.83c8,1.93,20.33-.6,20.33-.6"
                      class="cls-5"></path>
                <path data-v-f2009c9c="" d="M911,918.89h38c8.67,3.37,24.67,0,24.67,0" class="cls-5"></path>
                <path data-v-f2009c9c=""
                      d="M949,931.11V854.48a13.43,13.43,0,0,0-1-5l-24.77-61.41a19.45,19.45,0,0,0-10.07-10.46l-.11-.05a12.4,12.4,0,0,0-5.06-1.08H849a19.34,19.34,0,0,0-13.64,5.65h0a8.07,8.07,0,0,0-2.33,5.63v81a14,14,0,0,0,4.21,12.42l8.44,11,33,.67s29.33,3.67,35,36.67l30,1.64"
                      class="cls-9"></path>
                <line data-v-f2009c9c="" x1="913.67" y1="937.51" x2="943.85" y2="937.51" class="cls-5"></line>
                <path data-v-f2009c9c=""
                      d="M969.33,925.35v7a1.15,1.15,0,0,1-1.15,1.17h0a1.17,1.17,0,0,1-1.17-1.17v-7a1.18,1.18,0,0,1,1.17-1.17h0a1.16,1.16,0,0,1,1.16,1.16Z"
                      class="cls-5"></path>
                <path data-v-f2009c9c="" d="M842,826.18c4.67,3,107,38.34,107,38.34" class="cls-5"></path>
                <path data-v-f2009c9c=""
                      d="M935.35,852.6a5,5,0,0,0,2.75-6.51l-20.6-53.32A15.51,15.51,0,0,0,903,782.85H849.78a7.79,7.79,0,0,0-7.78,7.78h0v25.55a7.92,7.92,0,0,0,5.35,7.49l84.41,29A4.94,4.94,0,0,0,935.35,852.6Z"
                      class="cls-5"></path>
                <polygon data-v-f2009c9c="" points="855 860.18 837.67 860.18 837.67 868.52 859.74 868.52 855 860.18"
                         class="cls-5"></polygon>
                <rect data-v-f2009c9c="" x="913.67" y="857.85" width="7" height="12" class="cls-5"></rect>
                <path data-v-f2009c9c=""
                      d="M930,768.52l-3.14,3.59a9.16,9.16,0,0,0-1.47,9.75L948.67,834a11.36,11.36,0,0,0,7.79,6.42c4.76,1.09,11,2.6,11.69,3.09"
                      class="cls-5"></path>
                <path data-v-f2009c9c=""
                      d="M823.67,859.18l1.66-9s-.33-64.33,0-70.66c.12-1.29.12-2.27.12-3.07a9.9,9.9,0,0,1,8.75-9.57c18.75-2,70.33.51,76.8,1.64,7.67,1.34,14.39,13.34,14.39,13.34"
                      class="cls-5"></path>
                <path data-v-f2009c9c=""
                      d="M928.52,784.9c-.72-.44-.79,26.07-1.31,37a2.26,2.26,0,0,0,.38,1.34,2.14,2.14,0,0,0,3,.55l0,0,5.1-3.54.56-23.24C936.27,795.31,934,788.22,928.52,784.9Z"
                      class="cls-5"></path>
                <path data-v-f2009c9c=""
                      d="M917.15,870.06l1.27-24a5.89,5.89,0,0,1,2.37-4.16l12.58-9.12a3.79,3.79,0,0,0,1.35-4.35l-2.16-6.16"
                      class="cls-5"></path>
                <path data-v-f2009c9c="" d="M949,861.68c6.83,1.25,22,8.38,22,8.38" class="cls-5"></path>
                <path data-v-f2009c9c="" d="M949,854.52c6.83-1.9,22,4,22,4" class="cls-5"></path>
                <rect data-v-f2009c9c="" x="439.02" y="682.52" width="375.7" height="156.88"
                      class="dmg__section-65 cls-1"></rect>
                <rect data-v-f2009c9c="" x="439.02" y="843.75" width="375.7" height="53.9" class="dmg__section-64 cls-2"></rect>
                <line data-v-f2009c9c="" x1="641.69" y1="895.71" x2="641.69" y2="843.75" class="cls-5"></line>
                <line data-v-f2009c9c="" x1="635.69" y1="895.71" x2="635.69" y2="843.75" class="cls-5"></line>
                <line data-v-f2009c9c="" x1="439.02" y1="872.18" x2="635.94" y2="872.18" class="cls-5"></line>
                <line data-v-f2009c9c="" x1="641.94" y1="872.18" x2="814.71" y2="872.18" class="cls-5"></line>
                <rect data-v-f2009c9c="" x="792.42" y="877.77" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="769.43" y="877.77" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="739.18" y="877.77" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="711.93" y="877.77" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="685.3" y="877.77" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="585.19" y="877.77" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="562.19" y="877.77" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="531.94" y="877.77" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="504.69" y="877.77" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="478.06" y="877.77" width="4.25" height="19.88" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="775.35" y="910.7" width="5.33" height="15.15" class="cls-5"></rect>
                <rect data-v-f2009c9c="" x="656.68" y="910.7" width="5.67" height="15.15" class="cls-5"></rect>
                <path data-v-f2009c9c=""
                      d="M586.26,900.52c31.23,7.33,32.76,41.93,32.76,41.93h7.86c-3.86-23.56-13.18-33.93-27.53-41.93"
                      class="cls-5"></path>
                <path data-v-f2009c9c=""
                      d="M553.39,900.52c-29,12.33-25.33,41.93-25.33,41.93l6-.14c2-37.2,35.63-41.79,35.63-41.79"
                      class="cls-5"></path>
                <path data-v-f2009c9c="" d="M476.35,910.7s62.07.13,62.19,0" class="cls-5"></path>
                <line data-v-f2009c9c="" x1="469.69" y1="910.7" x2="470.35" y2="900.52" class="cls-5"></line>
                <rect data-v-f2009c9c="" x="625.98" y="925.85" width="179.37" height="11.86" class="cls-5"></rect>
                <line data-v-f2009c9c="" x1="612.75" y1="910.7" x2="821.67" y2="910.7" class="cls-5"></line>
                <rect data-v-f2009c9c="" x="527.87" y="942.58" width="4.07" height="21.94" class="cls-5"></rect>
                <line data-v-f2009c9c="" x1="139.3" y1="571.27" x2="139.3" y2="471.47" class="cls-5"></line>
                <path data-v-f2009c9c=""
                      d="M911.4,1213.1h-63.1c-1.9,0-3.4-1.5-3.4-3.4v-13.9c0-1.9,1.5-3.4,3.4-3.4h63.2c1.9,0,3.4,1.5,3.4,3.4v13.9 C914.8,1213.6,913.3,1215.1,911.4,1215.1z"
                      class="cls-0"></path>
                <path data-v-f2009c9c=""
                      d="M911.4,1087.8h-63.1c-1.9,0-3.4-1.5-3.4-3.4v-13.9c0-1.9,1.5-3.4,3.4-3.4h63.2c1.9,0,3.4,1.5,3.4,3.4v13.9 C914.8,1086.3,913.3,1087.8,911.4,1087.8z"
                      class="cls-0"></path>
                <path data-v-f2009c9c=""
                      d="M608,1218.7h-63.2c-1.9,0-3.4-1.5-3.4-3.4v-13.9c0-1.9,1.5-3.4,3.4-3.4H608c1.9,0,3.4,1.5,3.4,3.4v13.9 C611.4,1217.2,609.9,1218.7,608,1218.7z"
                      class="cls-0"></path>
                <path data-v-f2009c9c=""
                      d="M608,1194h-63.2c-1.9,0-3.4-1.5-3.4-3.4v-13.9c0-1.9,1.5-3.4,3.4-3.4H608c1.9,0,3.4,1.5,3.4,3.4v13.9 C611.4,1192.5,609.9,1194,608,1194z"
                      class="cls-0"></path>
                <path data-v-f2009c9c=""
                      d="M608,1074.5h-63.2c-1.9,0-3.4-1.5-3.4-3.4v-13.9c0-1.9,1.5-3.4,3.4-3.4H608c1.9,0,3.4,1.5,3.4,3.4v13.9 C611.4,1073,609.9,1074.5,608,1074.5z"
                      class="cls-0"></path>
                <path data-v-f2009c9c=""
                      d="M608,1100.1h-63.2c-1.9,0-3.4-1.5-3.4-3.4v-13.9c0-1.9,1.5-3.4,3.4-3.4H608c1.9,0,3.4,1.5,3.4,3.4v13.9 C611.4,1098.6,609.9,1100.1,608,1100.1z"
                      class="cls-0"></path>
                <rect data-v-f2009c9c="" x="843.4" y="1117.6" width="72.8" height="36" class="st7"></rect>
                <rect data-v-f2009c9c="" x="812.3" y="1125" width="28" height="17.4" class="st7"></rect>
                <polyline data-v-f2009c9c="" points="580,1131 780.8,1132 812.3,1132 " class="st4"></polyline>
                <polyline data-v-f2009c9c="" points="579.8,1136.3 780.9,1136.3 812.3,1136.3 " class="st4"></polyline>
                <path data-v-f2009c9c=""
                      d="M805.3,1212.5L805.3,1212.5c-3.9,0-7-3.1-7-7v-22c0-3.9,3.1-7,7-7l0,0c3.9,0,7,3.1,7,7v22 C812.3,1209.3,809.2,1212.5,805.3,1212.5z"
                      class="st7"></path>
                <path data-v-f2009c9c="" d="M805.3,1175.4v-11.3c0-2,1.6-3.6,3.6-3.6h11.3c2,0,3.6-1.6,3.6-3.6v-13.5"
                      class="st10"></path>
                <line data-v-f2009c9c="" x1="580.1" y1="1173.4" x2="580.1" y2="1100.1" class="st8"></line>
                <line data-v-f2009c9c="" x1="571.6" y1="1173.1" x2="571.6" y2="1099.9" class="st8"></line>
                <circle data-v-f2009c9c="" cx="654.1" cy="1134.5" r="13" class="st4"></circle>
            </svg>
        </div>
    )
}

export default LorrySVG