import { Box, Typography } from "@mui/material"


const AdminStatistic = () => {
    return(
        <Box>
            <Typography>Admin STatistic</Typography>
        </Box>
    )
}

export default AdminStatistic