const GenericSVG = ({ SvgData }) => {

    return (
        <div data-v-9df6b412="" className={SvgData} style={{ width: '60%' }}>
            <svg data-v-6585ac62="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.24 611.1">
                <path data-v-6585ac62=""
                      d="M151.6,594.4l69.3.2,6.1-15.7s-1.1-44.3-3.6-51.4l-2.5-7V481s-42.3,0-62,4.9l-7.1,1.6-4.2,1-15.8-1.2s-55.3,19.1-60.2,23.8L34,515.4l-8.5,9.1-4,17.2s-1.5,4.6-3,5.9-7.6,7.8-7.6,9.7-1.3,19.7,7,24.5c10.3,2.3,57.6,9.5,57.6,9.5s-3.9-44.7,39-45.2c28.4.4,38.9,23.4,36.9,46.7Z"
                      transform="translate(-4.65 -5.8)" class="dmg__section-3 cls-1"></path>
                <path data-v-6585ac62=""
                      d="M322,595.5l-96.5-.2,6.8-16.6-3.8-50.8-2.8-7.6V481.1s43.6-.5,74.7,20.6c11.6,7.9,24.9,17.3,29.8,21.9l6.2-3.3s73.7,16.8,90.2,21.8c-4.9,0,6.7,12.8,6.7,12.8l34.3,1.9s3.2,1.6,4.6,7.9l4.1-.2c1.4,1.2,1.1,6.1,1.1,9.6a1.79,1.79,0,0,1-1.8,1.8h0l-1.5,8.4h.5a1.79,1.79,0,0,1,1.8,1.8h0v.2a2.85,2.85,0,0,1-1.4,2.5,54.16,54.16,0,0,1-10.9,5.1c-17-.1-38.5,1.3-42.2,1.5a1,1,0,0,1-.9-.4,4.59,4.59,0,0,1-1-2.5c.1-8.5.8-33.4-21.8-43.2a39.49,39.49,0,0,0-26.9-1.3s-27.4,7.9-27.1,47.3l-22.2.2Z"
                      transform="translate(-4.65 -5.8)" class="dmg__section-2 cls-2"></path>
                <circle data-v-6585ac62="" cx="377.35" cy="578.6" r="32.5"></circle>
                <circle data-v-6585ac62="" cx="377.35" cy="577.7" r="23.7" class="cls-3"></circle>
                <circle data-v-6585ac62="" cx="377.35" cy="578.7" r="22.7" class="cls-4"></circle>
                <circle data-v-6585ac62="" cx="377.35" cy="578.5" r="10.9" class="cls-5"></circle>
                <circle data-v-6585ac62="" cx="108.75" cy="577.7" r="32.5"></circle>
                <path data-v-6585ac62=""
                      d="M75.7,591.2s-51.9-7-57.6-9.5c-8-4-7-25.8-7-25.8l6-7.1v-7.3l2-1.8L21,524.4l-1.9-.4c.3-3.8,7.5-12.1,7.5-12.1s31.3-3.6,35.8-3.5c29.4-15,80.3-30.1,108.4-32.3,66.1-3.5,93.5,1.3,112.3,7.8,23.3,9.4,65.5,35.1,65.5,35.1H356s60.3,8.4,87.7,16.5a42.63,42.63,0,0,1,16.3,8.9,27.7,27.7,0,0,1,5.5,6.3s.1,4.3,2.4,6"
                      transform="translate(-4.65 -5.8)" class="cls-3"></path>
                <path data-v-6585ac62=""
                      d="M433.3,555.1c1.4,2.9,6.9,19.8,6.9,19.8l35.4,1.1s-3.5-13.3-8.1-19C463.2,556.5,433.3,555.1,433.3,555.1Z"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62=""
                      d="M433.3,555.1s-7.3-9.9-7.8-11.2,1.1-1.7,1.1-1.7l26.9,2a2.46,2.46,0,0,1,1.9.9c2,2.3,7.5,6.6,8.4,8a3.83,3.83,0,0,1,0,3.6"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <line data-v-6585ac62="" x1="468.55" y1="562.4" x2="432.95" y2="561.7" class="cls-5"></line>
                <path data-v-6585ac62=""
                      d="M461.5,561.1l1.4,3.5a1,1,0,0,1-.7,1.3h-4.1a2,2,0,0,1-1.8-1.2l-1.4-3.5a.89.89,0,0,1,.5-1.1c.1,0,.2-.1.3-.1h4.1A1.66,1.66,0,0,1,461.5,561.1Z"
                      transform="translate(-4.65 -5.8)" class="cls-6"></path>
                <path data-v-6585ac62=""
                      d="M476.5,586.5l-29.9-1a5.17,5.17,0,0,1-3.2-1.1,2.59,2.59,0,0,1-1.1-1.4c-.5-1.5-2.1-8.1-2.1-8.1"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <circle data-v-6585ac62="" cx="450.45" cy="574.7" r="5" class="cls-6"></circle>
                <path data-v-6585ac62="" d="M453.5,544.2s-4.4-11.5-112.5-23.4" transform="translate(-4.65 -5.8)"
                      class="cls-6"></path>
                <path data-v-6585ac62=""
                      d="M348.4,519.2s-1.4,2.1-10.9,1.5a1.69,1.69,0,0,1-1-.3c-9.4-6.2-46-28.3-58.3-33.2a.75.75,0,0,1-.4-.9.78.78,0,0,1,.4-.4,46.81,46.81,0,0,1,4.8-1.8"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62="" d="M62.3,508.6s.5,1.1,8.9,2c14.5-6.8,63-24.3,63-24.3,98.4-19.5,144,.8,144,.8"
                      transform="translate(-4.65 -5.8)" class="cls-6"></path>
                <path data-v-6585ac62=""
                      d="M330.2,523.6s-23.9-22.7-54.2-34c-24.3-8.2-45.7-8.5-62-8.9-16.1-.4-77.4.6-122.7,32.4a2.94,2.94,0,0,0-1.1,3.3h0Z"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <line data-v-6585ac62="" x1="85.65" y1="510.6" x2="29.35" y2="509.6" class="cls-6"></line>
                <path data-v-6585ac62="" d="M52.3,523.1s241.3,6.4,280.6,7.9c43,1.5,92.7,12.9,92.7,12.9"
                      transform="translate(-4.65 -5.8)" class="cls-6"></path>
                <path data-v-6585ac62="" d="M20.9,524.6l31.4-1.5s-7.2,19.4-8.3,24.5c-.8,3.6-.4,7.5,11.1,9.1s28.6,3.4,28.6,3.4"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62=""
                      d="M317,509.9c2.4,2,8.7,8.4,1.2,15.9-3.7-1.1-7.2-2.4-10.8-3.8a3.15,3.15,0,0,1-1.9-2.9v-4.9s.9-3.9,9.8-4.8A2.16,2.16,0,0,1,317,509.9Z"
                      transform="translate(-4.65 -5.8)" class="cls-3"></path>
                <path data-v-6585ac62="" d="M321.3,521.3s-10-3.5-12.8-2.9l-2.8.6" transform="translate(-4.65 -5.8)"
                      class="cls-5"></path>
                <path data-v-6585ac62="" d="M220.9,520.4s1.6-39.4,0-39.4l10.8.3,2.5,39.5" transform="translate(-4.65 -5.8)"
                      class="cls-5"></path>
                <path data-v-6585ac62="" d="M223.5,587.4l3.6-8.6s-.3-.9,0-5.1c1.1-18.3-3.6-46.3-3.6-46.3l-2.5-7"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <polygon data-v-6585ac62="" points="245.05 529.8 229.65 529.8 231.65 527.8 243.25 527.8 245.05 529.8"
                         class="cls-6"></polygon>
                <polygon data-v-6585ac62="" points="163.25 527 147.95 527 149.95 525 161.55 525 163.25 527"
                         class="cls-6"></polygon>
                <line data-v-6585ac62="" x1="76.55" y1="558.4" x2="6.35" y2="550.2" class="cls-3"></line>
                <path data-v-6585ac62="" d="M30.9,584.5s-7.3-6-10-10.8c-2.2-3.2-8.6-1.9-8.6-1.9"
                      transform="translate(-4.65 -5.8)" class="cls-3"></path>
                <path data-v-6585ac62="" d="M25.6,524.5s-3.8,17.1-4.7,19.6-3.9,4.8-3.9,4.8" transform="translate(-4.65 -5.8)"
                      class="cls-3"></path>
                <line data-v-6585ac62="" x1="42.45" y1="533.3" x2="17.35" y2="534" class="cls-5"></line>
                <path data-v-6585ac62=""
                      d="M347.1,537.3h-3.8A1.32,1.32,0,0,1,342,536h0a1.32,1.32,0,0,1,1.3-1.3h3.8a1.32,1.32,0,0,1,1.3,1.3h0A1.32,1.32,0,0,1,347.1,537.3Z"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <circle data-v-6585ac62="" cx="108.75" cy="577.7" r="22.7" class="cls-4"></circle>
                <circle data-v-6585ac62="" cx="108.75" cy="577.2" r="10.9" class="cls-5"></circle>
                <line data-v-6585ac62="" x1="415.45" y1="579.8" x2="438.75" y2="578.6" class="cls-5"></line>
                <path data-v-6585ac62="" d="M345.6,580.6l-184-2.8-26.2-52.4,12.5-7c2-7.8,5.7-17.8,11-32.5"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <line data-v-6585ac62="" x1="212.35" y1="475.4" x2="209.85" y2="514" class="cls-5"></line>
                <line data-v-6585ac62="" x1="321.85" y1="590.5" x2="151.55" y2="588.6" class="cls-5"></line>
                <path data-v-6585ac62=""
                      d="M150,28.3l69.3-.2,6.1,15.7s-1.1,44.3-3.6,51.4l-2.5,7.1v39.4s-42.3,0-62-4.9l-7.1-1.6-4.2-1-15.8,1.2s-55.3-19.1-60.1-23.8l-37.5-4.4-8.6-9L20,81.1s-1.5-4.6-3-5.9-7.6-7.8-7.6-9.7S8.1,45.8,16.4,41C26.7,38.7,74,31.5,74,31.5s-4,44.7,38.9,45.3c28.4-.4,38.9-23.5,36.9-46.7Z"
                      transform="translate(-4.65 -5.8)" class="dmg__section-6 cls-7"></path>
                <path data-v-6585ac62=""
                      d="M320.4,27.1l-96.4.2,6.8,16.6L227,94.7l-2.8,7.6v39.1s43.6.5,74.7-20.6c11.6-7.9,24.9-17.3,29.8-21.9l6.2,3.3s73.7-16.8,90.2-21.8c-4.9,0,6.7-12.8,6.7-12.8L466,65.7s3.2-1.6,4.6-7.9l4.1.2c1.4-1.2,1.1-6.1,1.1-9.6a1.79,1.79,0,0,0-1.8-1.8h0l-1.5-8.4h.5a1.88,1.88,0,0,0,1.9-1.8h0v-.3a2.85,2.85,0,0,0-1.4-2.5,54.16,54.16,0,0,0-10.9-5.1c-17,.1-38.5-1.3-42.2-1.5a1,1,0,0,0-.9.4,4.59,4.59,0,0,0-1,2.5c0,8.5.8,33.4-21.8,43.2a39.49,39.49,0,0,1-26.9,1.3s-27.4-7.9-27.1-47.3Z"
                      transform="translate(-4.65 -5.8)" class="dmg__section-7 cls-2"></path>
                <circle data-v-6585ac62="" cx="375.75" cy="32.5" r="32.5"></circle>
                <circle data-v-6585ac62="" cx="375.75" cy="33.4" r="23.7" class="cls-3"></circle>
                <circle data-v-6585ac62="" cx="375.75" cy="32.4" r="22.7" class="cls-4"></circle>
                <circle data-v-6585ac62="" cx="375.75" cy="32.6" r="10.9" class="cls-5"></circle>
                <circle data-v-6585ac62="" cx="107.15" cy="33.4" r="32.5"></circle>
                <path data-v-6585ac62=""
                      d="M74.1,31.4s-51.9,7-57.6,9.5c-8,4-7,25.8-7,25.8l6,7.1v7.3l2,1.8,1.9,15.3-1.9.4c.3,3.8,7.5,12.1,7.5,12.1s31.3,3.6,35.8,3.5c29.4,15,80.3,30.1,108.4,32.3,66.1,3.5,93.5-1.3,112.3-7.8,23.3-9.4,65.5-35.1,65.5-35.1h7.4s60.3-8.4,87.7-16.5a42.63,42.63,0,0,0,16.3-8.9,27.7,27.7,0,0,0,5.5-6.3s.1-4.3,2.4-6"
                      transform="translate(-4.65 -5.8)" class="cls-3"></path>
                <path data-v-6585ac62=""
                      d="M431.8,67.6c1.4-2.9,6.9-19.8,6.9-19.8l35.4-1.1s-3.5,13.3-8.1,19C461.7,66.2,431.8,67.6,431.8,67.6Z"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62=""
                      d="M431.8,67.6s-7.3,9.9-7.8,11.2,1.1,1.7,1.1,1.7l26.9-2a2.46,2.46,0,0,0,1.9-.9c2-2.3,7.5-6.6,8.4-8a3.83,3.83,0,0,0,0-3.6"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <line data-v-6585ac62="" x1="466.95" y1="48.7" x2="431.45" y2="49.4" class="cls-5"></line>
                <path data-v-6585ac62=""
                      d="M459.9,61.6l1.4-3.5a1,1,0,0,0-.6-1.3h-4.2a2,2,0,0,0-1.8,1.2l-1.4,3.5a.89.89,0,0,0,.5,1.1c.1,0,.2.1.3.1h4.1A1.8,1.8,0,0,0,459.9,61.6Z"
                      transform="translate(-4.65 -5.8)" class="cls-6"></path>
                <path data-v-6585ac62=""
                      d="M475,36.2l-29.9,1a5.17,5.17,0,0,0-3.2,1.1,2.59,2.59,0,0,0-1.1,1.4c-.5,1.5-2.1,8.1-2.1,8.1"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <circle data-v-6585ac62="" cx="448.95" cy="36.4" r="5" class="cls-6"></circle>
                <path data-v-6585ac62="" d="M451.9,78.4S447.5,90,339.4,101.8" transform="translate(-4.65 -5.8)"
                      class="cls-6"></path>
                <path data-v-6585ac62=""
                      d="M346.8,103.4s-1.5-2.1-10.9-1.5a1.69,1.69,0,0,0-1,.3c-9.4,6.2-46,28.3-58.3,33.2a.69.69,0,0,0-.3.9q.15.3.3.3a46.81,46.81,0,0,0,4.8,1.8"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62="" d="M60.7,114.1s.5-1.1,8.9-2c14.5,6.8,63,24.3,63,24.3,98.4,19.5,144-.8,144-.8"
                      transform="translate(-4.65 -5.8)" class="cls-6"></path>
                <path data-v-6585ac62=""
                      d="M328.7,99s-23.9,22.7-54.2,34c-24.3,8.3-45.7,8.5-62,8.9-16,.4-77.4-.6-122.7-32.4a2.94,2.94,0,0,1-1.1-3.3h0Z"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <line data-v-6585ac62="" x1="84.05" y1="100.5" x2="27.85" y2="101.5" class="cls-6"></line>
                <path data-v-6585ac62="" d="M50.8,99.6s241.3-6.4,280.6-7.9c43-1.5,92.7-12.9,92.7-12.9"
                      transform="translate(-4.65 -5.8)" class="cls-6"></path>
                <path data-v-6585ac62="" d="M19.3,98.1l31.4,1.5s-7.2-19.4-8.3-24.5c-.8-3.6-.4-7.5,11.1-9.1s28.6-3.4,28.6-3.4"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62=""
                      d="M315.5,112.8c2.3-2,8.7-8.4,1.2-15.9-3.7,1.1-7.2,2.4-10.8,3.8a3.15,3.15,0,0,0-1.9,2.9v4.9s.9,4,9.8,4.8A2.16,2.16,0,0,0,315.5,112.8Z"
                      transform="translate(-4.65 -5.8)" class="cls-3"></path>
                <path data-v-6585ac62="" d="M319.7,101.4s-10,3.5-12.8,2.9l-2.8-.6" transform="translate(-4.65 -5.8)"
                      class="cls-5"></path>
                <path data-v-6585ac62="" d="M219.4,102.3s1.6,39.4,0,39.4l10.8-.3,2.6-39.5" transform="translate(-4.65 -5.8)"
                      class="cls-5"></path>
                <path data-v-6585ac62="" d="M221.9,35.2l3.6,8.6s-.3.9,0,5.1c1.1,18.3-3.6,46.3-3.6,46.3l-2.5,7.1"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <polygon data-v-6585ac62="" points="243.45 81.3 228.05 81.3 230.05 83.3 241.65 83.3 243.45 81.3"
                         class="cls-6"></polygon>
                <polygon data-v-6585ac62="" points="161.75 84.1 146.35 84.1 148.35 86 159.95 86 161.75 84.1"
                         class="cls-6"></polygon>
                <line data-v-6585ac62="" x1="74.95" y1="52.7" x2="4.85" y2="60.9" class="cls-3"></line>
                <path data-v-6585ac62="" d="M29.3,38.1s-7.3,6-10,10.8c-2.2,3.2-8.6,1.9-8.6,1.9"
                      transform="translate(-4.65 -5.8)" class="cls-3"></path>
                <path data-v-6585ac62="" d="M24,98.2s-3.8-17.1-4.7-19.6-3.9-4.8-3.9-4.8" transform="translate(-4.65 -5.8)"
                      class="cls-3"></path>
                <line data-v-6585ac62="" x1="40.85" y1="77.8" x2="15.85" y2="77.1" class="cls-5"></line>
                <path data-v-6585ac62=""
                      d="M345.6,87.9h-3.8a1.32,1.32,0,0,1-1.3-1.3h0a1.32,1.32,0,0,1,1.3-1.3h3.8a1.32,1.32,0,0,1,1.3,1.3h0A1.32,1.32,0,0,1,345.6,87.9Z"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <circle data-v-6585ac62="" cx="107.15" cy="33.4" r="22.7" class="cls-4"></circle>
                <circle data-v-6585ac62="" cx="107.15" cy="33.9" r="10.9" class="cls-5"></circle>
                <line data-v-6585ac62="" x1="413.85" y1="31.3" x2="437.25" y2="32.5" class="cls-5"></line>
                <path data-v-6585ac62="" d="M344,42.1,160,44.9,133.8,97.3l12.5,7c2,7.7,5.7,17.8,11,32.5"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <line data-v-6585ac62="" x1="210.75" y1="135.6" x2="208.25" y2="97" class="cls-5"></line>
                <line data-v-6585ac62="" x1="320.25" y1="20.6" x2="149.95" y2="22.5" class="cls-5"></line>
                <path data-v-6585ac62=""
                      d="M125.9,319.5c-.2,33.3,6.4,52.1,6.4,52.1l-58,7.8L49,392.9,40.6,396l-6.6-.2s-11-.6-18.8-14.3C9,370.8,5.5,351.9,5.4,319,20.3,319.4,125.9,319.5,125.9,319.5Z"
                      transform="translate(-4.65 -5.8)" class="dmg__section-4 cls-2"></path>
                <path data-v-6585ac62=""
                      d="M125.9,316.7c-.2-33.3,6.8-55.1,6.8-55.1l-58.5-7.8L49,240.3l-8.5-3.1-6.6.2s-11,.6-18.8,14.3c-6.2,10.8-9.6,31.6-9.8,64.5C20.3,315.8,125.9,316.7,125.9,316.7Z"
                      transform="translate(-4.65 -5.8)" class="dmg__section-5 cls-1"></path>
                <path data-v-6585ac62=""
                      d="M350.7,314.2l130.5,1.2s-1.37-23.13-1.87-28.53-2.73-6.77-2.73-6.77a58.49,58.49,0,0,0-5.12-14.25c-.3-.7-2-3.56-2.27-4.26-2.3-5-14.71-20.89-32.21-25.69a13.69,13.69,0,0,0-8.7.5L349.5,231l-11.1,10.4-3.3,3.4s16.4,38.9,15.6,65.2Z"
                      transform="translate(-4.65 -5.8)" class="dmg__section-8 cls-8"></path>
                <path data-v-6585ac62=""
                      d="M350.7,323.6c.8,26.3-14.1,67.2-14.1,67.2l1.8,1.4,11.8,8.6,78.1-3.6a13.69,13.69,0,0,0,8.7.5c17.5-4.7,29.36-13.53,36-33,.9-2.63,2.1-5.15,2.84-7.83h0s2.95-8.29,3.12-10.52c.6-8,2.1-27.36,2.1-27.36L350.7,317.4Z"
                      transform="translate(-4.65 -5.8)" class="dmg__section-1 cls-8"></path>
                <path data-v-6585ac62=""
                      d="M34,395.8l42.4,11.7,63.6.9,2.8-1.3,279.1-2a3.32,3.32,0,0,0,2.6-1.3l1.4-1.7c45.5-6.7,51.7-51.3,51.7-51.3,1.2-3.3,3.4-29.4,3.6-34v-1.4c-.2-4.5-1.4-29.6-2.6-33l-2-2.3s-7.7-41-50.7-49l-1.4-1.7a3.32,3.32,0,0,0-2.6-1.3l-279.1-2-2.7-1.2-63.6.9L34,237.5"
                      transform="translate(-4.65 -5.8)" class="cls-3"></path>
                <path data-v-6585ac62=""
                      d="M478.8,283.5s-7.1-4.7-10.5-2.4c-1.1,1-1.8,1.4-1.9,2.9s.5,25.8.6,29.7v3c.07-2.55-.79,29.56-.6,31.5.1,1.5.7,3.9,1.9,4.9,3.4,2.4,10.5-4.4,10.5-4.4"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62=""
                      d="M470.1,361.6a1.23,1.23,0,0,0-.8-1.6.64.64,0,0,0-.6,0h0c-.8.2-3.9,1.5-6.5,2.6a11.85,11.85,0,0,0-5.6,5c-3.4,5.8-11.2,18-22.1,25.2-.8.5-1.6,1-2.4,1.4-1.6.9-3.1,2-2.9,2.6s8.7.5,8.7.5S461,389.8,470.1,361.6Z"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62=""
                      d="M469.3,271.5a1.31,1.31,0,0,1-.8,1.6.64.64,0,0,1-.6,0h0c-.8-.2-3.4-1.3-5.8-2.3a13.53,13.53,0,0,1-6.6-5.9c-3.5-6-11.2-17.6-21.7-24.6-.8-.5-1.6-1-2.4-1.4-1.6-.9-3.1-2-2.9-2.6s8.7-.5,8.7-.5S460.2,243.4,469.3,271.5Z"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62="" d="M432.5,264.5s-53.1-20.7-97.4-19.7" transform="translate(-4.65 -5.8)"
                      class="cls-6"></path>
                <path data-v-6585ac62="" d="M426.6,373.7c2-.5-56.5,16.3-90,17.1" transform="translate(-4.65 -5.8)"
                      class="cls-6"></path>
                <path data-v-6585ac62=""
                      d="M136.6,262.4c-.1,0,59.9,2.9,81.3,2.9,22,0,60.5-5.3,60.5-5.3,11.8,71.5-1.1,113.7-1.1,113.7s-37.1-6-59.1-6c-21.3,0-81.3,2.7-81.3,2.7C136.9,369.9,122.1,329.9,136.6,262.4Z"
                      transform="translate(-4.65 -5.8)" class="dmg__section-9 cls-7"></path>
                <path data-v-6585ac62=""
                      d="M329.7,237.4a.68.68,0,0,1,.7.7.56.56,0,0,1-.5.6c-12,3.4-30,9.5-61.8,16.3-26.9,5.8-82.8,1.7-92.8,1.7-9,0-74.2-9.3-87.3-11.2a1,1,0,0,1-.9-1.2,1.49,1.49,0,0,1,.3-.7l.8-.8a3.33,3.33,0,0,1,2.1-.9c12-.4,107.7-3.8,135.8-4.5C251.5,236.9,312.4,237.2,329.7,237.4Z"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <line data-v-6585ac62="" x1="301.75" y1="239.7" x2="301.75" y2="231.4" class="cls-5"></line>
                <line data-v-6585ac62="" x1="227.35" y1="231.5" x2="216.55" y2="252.3" class="cls-5"></line>
                <line data-v-6585ac62="" x1="206.75" y1="252.1" x2="210.85" y2="231.9" class="cls-5"></line>
                <line data-v-6585ac62="" x1="146.35" y1="248.3" x2="133.35" y2="234.5" class="cls-5"></line>
                <path data-v-6585ac62="" d="M144.5,253.3l-15-12.7s-5.2-5.4,0-9.3,13.2-5.1,13.2-5.1"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62="" d="M215.5,237.7c-4.2-7.6,16.5-10.9,16.5-10.9" transform="translate(-4.65 -5.8)"
                      class="cls-5"></path>
                <path data-v-6585ac62=""
                      d="M319.2,240.3l-6.8,1.1v-1.7l-3.5-2s-6.7-18.2-6.9-19.2c.3-1.2,4.3-1.2,6,1S319.2,240.3,319.2,240.3Z"
                      transform="translate(-4.65 -5.8)" class="cls-3"></path>
                <line data-v-6585ac62="" x1="234.65" y1="223.7" x2="230.45" y2="223.7" class="cls-5"></line>
                <line data-v-6585ac62="" x1="139.35" y1="223.7" x2="135.05" y2="223.7" class="cls-5"></line>
                <polyline data-v-6585ac62="" points="331.95 385 326.35 389.6 330.25 399.8" class="cls-5"></polyline>
                <path data-v-6585ac62=""
                      d="M330.4,395.8a.67.67,0,0,0,.7-.6.75.75,0,0,0-.5-.7c-12-3.4-30-9.5-61.8-16.3-26.9-5.8-82.7-1.7-92.7-1.7-9,0-74.2,9.3-87.3,11.2a1,1,0,0,0-.9,1.2,1.49,1.49,0,0,0,.3.7l.8.8a4,4,0,0,0,2.1.9c12,.4,107.7,3.8,135.8,4.5C252.2,396.3,313.1,395.9,330.4,395.8Z"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <line data-v-6585ac62="" x1="302.45" y1="381.8" x2="302.45" y2="390.1" class="cls-5"></line>
                <line data-v-6585ac62="" x1="228.05" y1="390.1" x2="217.25" y2="369.3" class="cls-5"></line>
                <line data-v-6585ac62="" x1="207.45" y1="369.5" x2="211.55" y2="389.7" class="cls-5"></line>
                <line data-v-6585ac62="" x1="147.05" y1="373.3" x2="134.05" y2="387.1" class="cls-5"></line>
                <path data-v-6585ac62="" d="M145.2,379.9l-15,12.7s-5.2,5.4,0,9.3,13.2,5.1,13.2,5.1"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62=""
                      d="M319.9,392.9l-6.8-1.1v1.7l-3.5,2s-6.7,18.2-6.8,19.2c.3,1.2,4.3,1.2,6-1S319.9,392.9,319.9,392.9Z"
                      transform="translate(-4.65 -5.8)" class="cls-3"></path>
                <line data-v-6585ac62="" x1="237.85" y1="397.8" x2="231.15" y2="397.8" class="cls-5"></line>
                <line data-v-6585ac62="" x1="140.95" y1="397.8" x2="135.75" y2="397.8" class="cls-5"></line>
                <path data-v-6585ac62=""
                      d="M14,345.3h0a1.32,1.32,0,0,1-1.3-1.3V288.7a1.32,1.32,0,0,1,1.3-1.3h0a1.32,1.32,0,0,1,1.3,1.3V344A1.48,1.48,0,0,1,14,345.3Z"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62=""
                      d="M45.5,394h0l-4.9,2s-11.5-3.6-15.3-8.8-9-19.1-9-20.3c2.6,1.9,4.3,3.3,5.2,4a2.36,2.36,0,0,1,.9,1.6c.6,3.4,3.8,15.4,17.6,18.4"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62="" d="M16.3,367s-14.9-34.2-1.1-99.2" transform="translate(-4.65 -5.8)"
                      class="cls-5"></path>
                <circle data-v-6585ac62="" cx="362.85" cy="338.7" r="1.3" class="cls-9"></circle>
                <circle data-v-6585ac62="" cx="363.05" cy="284" r="1.3" class="cls-9"></circle>
                <path data-v-6585ac62="" d="M349.5,231c-29.5-.5-213.5-.2-226.7.4s-69.5,6.3-78.3,7.8"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62="" d="M74.2,379.4s-20.8-62.3,0-125.6" transform="translate(-4.65 -5.8)"
                      class="cls-5"></path>
                <path data-v-6585ac62="" d="M350.2,400.8c-29.5.5-213.3,1.5-226.5,1-13.7-.5-66.1-4.7-73.4-6.3"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62=""
                      d="M44.7,238.7l-5.8,4.2a25.26,25.26,0,0,0-17.6,19.3,3.19,3.19,0,0,1-.9,1.6l-5.2,4c0-1.1,5.3-15.1,9-20.3s14.5-11,14.5-11l6,2.2"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62=""
                      d="M50.7,238.3a11.76,11.76,0,0,0-9.9,3.3c-3.1,3.5-19.7,26.8-17.1,83.5,1.8,39.6,9.1,60.3,16.4,65.9a15.93,15.93,0,0,0,5.4,3"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62="" d="M336.6,390.8s15.9-29.6,14-85.9c-.6-18-7.4-37.8-15.6-60.1l-4.8-7.1,2.9-6.8"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
                <path data-v-6585ac62=""
                      d="M283.3,375l48.3,10.6.6-1.6c26.4-63.2-2.1-139.1-2.1-139.1l-46.8,13.7C283.3,258.5,298,309.7,283.3,375Z"
                      transform="translate(-4.65 -5.8)" class="cls-5"></path>
            </svg>
        </div>
    )
}

export default GenericSVG
