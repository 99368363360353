const PassangerCarSVG = ({ SvgData }) => {
    
    return (
        <div data-v-9df6b412="" className={SvgData}>
                    <svg data-v-7a1233dc="" xmlns="http://www.w3.org/2000/svg" viewBox="0 10 894.33 817.52">
                        <path data-v-7a1233dc=""
                              d="M627.5,663.4c-0.1,0-0.3,0-0.4,0v10.9c0,6.6-2.4,12-9,12h-60.5c-6.6,0-12-5.4-12-12v-11.9  c-23.1-0.3-171.9,0.9-192,1v12c0,6.6-5.4,12-12,12H283c-6.6,0-12-5.4-12-12v-11.5c-1.4-0.1-12.1-0.3-12-0.4  c-9.8,0.2-39.9-2-46.4,10.7c-7,13.6-9.6,68.6-4.8,121.1c1.9,27.5,23.5,20.7,63.9,21.8v-8.4c0-6.6,5.4-12,12-12h58.5  c6.6,0,12,5.4,12,12v10.5c0.3,0.1,0.5,1.2,0.5,1.3c6.1,0.2,175.2,1,188.9,1.2l2.7,0l-0.5-13.8c0-6.6,6.4-12,13-12h57.5  c6.6,0,12,5.4,12,12v12.5c12.1-0.1,55.8,0.4,55.3-74C682.5,707.1,678.2,663.5,627.5,663.4z"
                              className="cls-13 dmg__section-25"></path>
                        <path data-v-7a1233dc=""
                              d="M812.22 291.88l45.32-.58v-73.9c0-4.3-11.37-9.55-16.82-10.75s-18.62-1.47-18.62-1.47-6.21 5.95-9.46 9.69c-8-.23-11 3.31-11 3.31l-.11 26.7a3.09 3.09 0 0 0 1.87 2.85c3.18 1.36 9.47 4 11.38 4 1 2.64 4.86 5.06 4.86 5.06v3c-5.08-.26-7.93 2.47-7.83 8.16s.41 23.93.41 23.93z"
                              stroke-width="1.3" stroke="#000" stroke-miterlimit="10" className="dmg__section-20"></path>
                        <path data-v-7a1233dc=""
                              d="M812.23 294.38l45.37-.38v79.4c0 4.3-13.8 7.18-19.24 8.38S821 383.43 821 383.43a61.48 61.48 0 0 1-8.33-7.49c-8 .23-11-3.3-11-3.3l-.11-26.7a3.09 3.09 0 0 1 1.87-2.85c3.18-1.37 9.48-4 11.39-4a28.75 28.75 0 0 1 5.63-4.17 15 15 0 0 1-1.25-4.14c-4.29.06-7.15-2-7-7.73s.03-28.67.03-28.67z"
                              className="dmg__section-21 cls-2"></path>
                        <path data-v-7a1233dc=""
                              d="M530.88 492.07l3.21-2.3s73.65 16.84 90.21 21.8c-4.86 0 6.67 12.84 6.67 12.84l34.25 1.91s3.22 1.55 4.64 7.89L674 534c1.44 1.24 1.1 6.1 1.11 9.6a1.75 1.75 0 0 1-1.76 1.76l-1.5 8.36h.53a1.85 1.85 0 0 1 1.85 1.85v.25a3 3 0 0 1-1.38 2.54 56.56 56.56 0 0 1-10.87 5.13c-17-.12-38.47 1.26-42.16 1.5a1.08 1.08 0 0 1-.94-.41 4.37 4.37 0 0 1-1-2.46c.05-8.55.84-33.38-21.79-43.17a39.47 39.47 0 0 0-26.87-1.33s-27.39 7.88-27.08 47.29h-18.03l7.75-14.91s4-38.44 2.75-49.57c-3.5-6.62-3.73-8.36-3.73-8.36z"
                              className="dmg__section-10 cls-3"></path>
                        <path data-v-7a1233dc=""
                              d="M498.07 471.15c11.6 7.86 24.91 17.27 29.81 21.92l2.67 7.25s3.5 31.61-3.27 49.29c-3.28 5.68-7.65 15.26-7.65 15.26l-96.46-.17 6.76-16.58-3.76-50.83-2.81-7.6v-39.11s43.58-.51 74.71 20.57z"
                              className="dmg__section-11 cls-4"></path>
                        <path data-v-7a1233dc=""
                              d="M352.24 563.7l66.33.17 6.12-15.73s-1.08-44.34-3.6-51.39l-2.52-7.06v-39.4s-42.31 0-62 4.89l-7.14 1.56-13.18 30.95-2.44 6.12 29.48 53.26z"
                              className="dmg__section-12 cls-5"></path>
                        <path data-v-7a1233dc=""
                              d="M357.94 547l-29.53-52.3 16.75-36.89-15.8-1.24s-55.25 19.07-60.13 23.78l-37.55 4.38-8.46 9.11-4 17.14s-1.54 4.57-3 5.91-7.55 7.81-7.54 9.68-1.27 19.69 7 24.53c10.28 2.34 57.62 9.5 57.62 9.5s-3.94-44.65 38.93-45.28c28.38.4 38.85 23.45 36.92 46.69l5.44-8.12z"
                              className="dmg__section-13 cls-2"></path>
                        <path data-v-7a1233dc=""
                              d="M33 292.64h32.17s1-47.06.27-71.55a25.85 25.85 0 0 0-1.83-9.86 5.27 5.27 0 0 0-4.81-3.14h-1s-17.61-1-23.78 8.66c-1.53 2.83-1.02 70.88-1.02 75.89z"
                              className="dmg__section-15 cls-4"></path>
                        <path data-v-7a1233dc=""
                              d="M33.22 296.35l31.94-.17s1 47.23.28 71.73a25.9 25.9 0 0 1-1.83 9.86 5.27 5.27 0 0 1-4.81 3.14h-1s-17.61 1-23.78-8.67c-1.53-2.83-.8-70.96-.8-75.89z"
                              className="dmg__section-14 cls-2"></path>
                        <path data-v-7a1233dc=""
                              d="M338.22 239.75c-.07 0 59.93 2.9 81.29 2.9 22 0 60.46-5.33 60.46-5.33C491.8 308.79 478.91 351 478.91 351s-37.09-6-59.09-6c-21.34 0-81.29 2.69-81.29 2.69.01-.44-14.79-40.41-.31-107.94z"
                              className="dmg__section-23 cls-5"></path>
                        <path data-v-7a1233dc=""
                              d="M206.82 286.54c1.62-70 28.59-71.72 28.59-71.72l6.55-.18 8.45 3.12 25.21 13.51 58.46 7.79s-13.63 68.83 1.26 109.87l-59.46 7.78-25.21 13.52-8.45 3.12-6.55-.18s-28.27-1.82-28.67-76.82v-.34c-.19-3.15-.25-6.32-.18-9.47z"
                              stroke-width="1.3" stroke="#000" stroke-miterlimit="10" className="dmg__section-24"></path>
                        <path data-v-7a1233dc=""
                              d="M648.89 358.28c-5.06 5.5-11.5 11.79-15.13 13.25s-2.92 2.56-2.92 2.56-38.57 3.32-79 4l-15-11.67L482.72 351s15.33-43.26.83-113.69l53.17-15.2 3.33-3.42 11.11-10.38 78.8 5.41s15.42 9.23 25.09 25.46l2.21 3.8a11.19 11.19 0 0 0 5.31 4.7l5.86 2.48 1.52 7.31s-2.36.6-1.92 6 .46 38.25.46 38.25l-.4 23.71s-.19 3.45 2.85 4.45c0 0-1.66 6.23-3.26 8.37l-3.28 1.41a14.26 14.26 0 0 0-6.66 5.88c-2.23 3.79-5.74 9.35-8.85 12.74z"
                              stroke-width="1.3" stroke="#000" stroke-miterlimit="10" className="dmg__section-22"></path>
                        <path data-v-7a1233dc="" xmlns="http://www.w3.org/2000/svg"
                              d="M833.42,383.45l5.84-1.93s13.72-2.59,16.87-5.5c1.67-1.53,1.33-4.63,1.29-8,0-3.86-.36-8-.36-8l16.51-.15s4.15-.25,4.35,2.5c.13,1.91.3,14,0,18.81a2.42,2.42,0,0,1-2.27,2.26l-1.92-.41Z"
                              className="cls-bottom"></path>
                        <path data-v-7a1233dc=""
                              d="M50.88 383.33l6.41.2-6.69-3c-.24-.06-10.81-1.71-14.94-6.4-2-2.29-2.09-3.37-2-6.77v-4.9s-.22-3-.25-3l-20.28-.34a3.38 3.38 0 0 0-2.75 2.75 167.67 167.67 0 0 0-.19 17.81c.08 1.22.27 2.75 1.49 2.82l2.07.26 37.15.71c6.24.22.02-.11-.02-.14zM54.11 205.79l-3 2.48s-11.19 2.38-15.69 6.26c-1.71 1.47-1.83 2.31-1.83 6.56 0 3.81-.24 7.83-.24 7.83l-21.69-.12s-1.92.46-2.3-1.94c-.29-1.88-.3-14 0-18.81a2.42 2.42 0 0 1 2.27-2.26l1.92.42z"
                              className="cls-bottom"></path>
                        <path data-v-7a1233dc=""
                              d="M207.01 293.5v1M235.67 373.17L278 384.86l63.56.93 2.75-1.29 279.07-2a3.26 3.26 0 0 0 2.58-1.27l1.35-1.74c43-8 50.67-49 50.67-49l2-2.33c1.21-3.34 2.43-29.38 2.63-34v-1.38c-.21-4.5-1.42-29.64-2.63-33l-2-2.34s-7.67-41-50.67-49l-1.31-1.67a3.3 3.3 0 0 0-2.58-1.28l-279.07-2-2.75-1.29-63.56.94-42.36 11.69"
                              className="cls-8"></path>
                        <path data-v-7a1233dc=""
                              d="M680.44 260.84s-7.12-5.73-10.49-3.36a5.47 5.47 0 0 0-1.88 3.88c-.23 1.39.52 27.77.63 31.66v1c-.11 3.88-.84 30.09-.61 31.48a5.47 5.47 0 0 0 1.88 3.88c3.37 2.37 10.48-3.36 10.48-3.36M671.76 339a1.26 1.26 0 0 0-1.46-1.63c-.83.18-3.89 1.47-6.46 2.58a11.87 11.87 0 0 0-5.6 5c-3.36 5.82-11.23 18-22.05 25.21-.77.52-1.58 1-2.39 1.43-1.58.89-3.13 2-2.92 2.56s8.67.5 8.67.5 23.14-7.55 32.21-35.65zM670.89 248.86a1.26 1.26 0 0 1-1.47 1.63 61.55 61.55 0 0 1-5.8-2.3 14.14 14.14 0 0 1-6.6-5.84c-3.53-6-11.24-17.61-21.7-24.63-.78-.52-1.59-1-2.4-1.43-1.57-.88-3.12-2-2.92-2.55s8.67-.5 8.67-.5 23.14 7.47 32.22 35.62z"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M634.13 241.79s-53.08-20.69-97.41-19.67M628.23 351c2-.5-56.51 16.32-90 17.13"
                              className="cls-10"></path>
                        <path data-v-7a1233dc=""
                              d="M531.3 214.69a.66.66 0 0 1 .18 1.29c-12 3.39-30 9.49-61.83 16.32-26.88 5.77-82.75 1.67-92.76 1.67-9 0-74.17-9.29-87.26-11.17a1.1 1.1 0 0 1-.61-1.89l.83-.77a3.27 3.27 0 0 1 2.12-.88c12-.43 107.67-3.84 135.8-4.54 25.34-.5 86.28-.15 103.53-.03zM508.05 222.84v-8.28M433.67 214.63l-10.83 20.75M413 235.22l4.17-20.21M352.68 231.43l-13.01-13.85"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M346.18 230.59l-15-12.71s-5.16-5.44 0-9.33 13.16-5.05 13.16-5.05M417.17 215c-4.17-7.6 16.5-10.87 16.5-10.87"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M520.84 217.58L514 218.7v-1.65l-3.5-2s-6.67-18.17-6.84-19.17c.34-1.17 4.34-1.17 6 1s11.18 20.7 11.18 20.7z"
                              className="cls-8"></path>
                        <path data-v-7a1233dc=""
                              d="M440.97 206.88h-4.27M345.59 206.88h-4.26M538.2 368.14l-5.54 4.58 3.88 10.22"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M532 373.12a.66.66 0 0 0 .18-1.29c-12-3.39-30-9.5-61.82-16.32-26.88-5.77-82.76-1.67-92.76-1.67-9 0-74.18 9.29-87.26 11.17a1.1 1.1 0 0 0-.61 1.89l.83.77a3.27 3.27 0 0 0 2.12.88c11.95.43 107.67 3.84 135.79 4.54 25.34.5 86.28.14 103.53.03zM508.74 364.97v8.28M434.37 373.18l-10.83-20.76M413.7 352.58l4.17 20.21M353.38 356.38l-13.01 13.85"
                              className="cls-9"></path>
                        <path data-v-7a1233dc="" d="M346.88 357.21l-15 12.72s-5.16 5.44 0 9.32 13.12 5.06 13.12 5.06"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M521.54 370.23l-6.84-1.12v1.65l-3.5 2S504.54 391 504.37 392c.33 1.17 4.33 1.17 6-1s11.17-20.77 11.17-20.77z"
                              className="cls-8"></path>
                        <path data-v-7a1233dc="" d="M444.09 380.93h-6.69M347.21 380.93h-5.19" className="cls-9"></path>
                        <rect data-v-7a1233dc="" x="214.34" y="264.84" width="2.5" height="57.75" rx="1.25" ry="1.25"
                              transform="rotate(180 215.59 293.71)" className="cls-9"></rect>
                        <path data-v-7a1233dc=""
                              d="M247.09 371.34l-4.87 2s-11.5-3.64-15.25-8.76-9-19.13-9-20.25c2.63 1.93 4.3 3.24 5.19 4a2.69 2.69 0 0 1 1 1.57c.63 3.4 3.83 15.41 17.61 18.43M243.9 219.45c-13.56 4.11-20.28 16.71-20.92 20.12a2.57 2.57 0 0 1-.94 1.56c-.89.72-2.57 2-5.2 4 0-1.13 5.25-15.13 9-20.25s14.47-11 14.47-11l6.06 2.19M218 344.34s-14.88-34.23-1.13-99.24"
                              className="cls-9"></path>
                        <circle data-v-7a1233dc="" cx="569.09" cy="321.87" r="1.28" className="cls-11"></circle>
                        <circle data-v-7a1233dc="" cx="569.37" cy="267.09" r="1.28" className="cls-11"></circle>
                        <path data-v-7a1233dc=""
                              d="M33.37 228.92H12.61a4.4 4.4 0 0 1-3.12-1.29 1.14 1.14 0 0 1-.33-.81V209a4.13 4.13 0 0 1 .36-1.71 2 2 0 0 1 1.82-1.16l41.32-.33s26.5-1.66 29.83-1.16l1.5 1.16s12.5.67 16 3.17a56.62 56.62 0 0 0 8 8.5c2 1.5 24.34 10.17 30 14.5 5.17 2.83 9.34 5.5 10.5 7.5s4.17 27.33 4.34 53.67v3c-.17 26.33-3.17 51.66-4.34 53.66s-5.33 4.67-10.5 7.5c-5.66 4.34-28 13-30 14.5a57.11 57.11 0 0 0-8 8.5c-3.5 2.5-16 3.17-16 3.17l-1.5 1.17c-3.33.5-29.83-1.17-29.83-1.17l-40-.62a2.58 2.58 0 0 1-1.91-.89 3.5 3.5 0 0 1-.87-2.3v-16.82a4.2 4.2 0 0 1 1.61-3.31.52.52 0 0 1 .32-.11h21.55"
                              className="cls-8"></path>
                        <path data-v-7a1233dc=""
                              d="M53.66 292.64v-49.8c0-2.75-2.83-9.34-6.25-9.5a16 16 0 0 1-5.41-1.09l-8.38-11.16M33.61 367.31L42 356.15a16 16 0 0 1 5.42-1.09c3.42-.16 6.25-6.75 6.25-9.5v-49.32M46.03 296.28v58.9M46.03 233.22v59.42M65.16 382.94s3.75-8.39 5.87-9.64 27.8-1 31.25-3.63c4.13-3.12 8.5-9.62 9.88-18.87s2.37-52.87 2.37-52.87v-7.64s-1-43.62-2.37-52.87-5.75-15.75-9.88-18.87c-3.45-2.62-29.12-2.38-31.25-3.63s-5.87-8.64-5.87-8.64"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M149.29 345.53s-21.63 7-32.88 7.2l-4.25-1.87s-15.94-6.14-25.56-7.52a2.73 2.73 0 0 1-1.45-.64c-3.68-3.29-7.41-9.29-8.12-12.72-.12-4.25 0-33.75 0-33.75v-4.18s-.12-29.5 0-33.75c.71-3.43 4.44-9.43 8.12-12.72a2.81 2.81 0 0 1 1.45-.64c9.62-1.38 25.56-7.52 25.56-7.52l4.25-1.87c11.25.25 32.88 7.2 32.88 7.2M86.79 216.26s-1.4 24.74-1.64 29.32M85.15 342.7l1.64 29.26"
                              className="cls-8"></path>
                        <path data-v-7a1233dc=""
                              d="M103 212.49l-.69 6.06s1.74 13.21 1.5 21.85v107.49s.36 18-1.52 21.78a45.34 45.34 0 0 1 .78 6.92"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M110.93 218.9c.45.21-.27 3.27 1.6 4.65s20.55 7.5 25.46 8.37M138 356.88c-4.91.87-23.58 7-25.46 8.37s-1.15 4.44-1.6 4.65"
                              className="cls-8"></path>
                        <path data-v-7a1233dc="" d="M139.63 239.92s8.59 49.25 0 108.44" className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M112.53 223.55l3.88 12s2.48 101 0 117.18c-2.88 8.57-3.88 12.52-3.88 12.52M104 325.92s-11.9-1.2-15.2-3.26c-2.63-1.65-2.23-4.13-2.26-6v-22.05-23.31c0-2.23-.3-4.8 2.67-6.18C92.73 263.47 104 261 104 261M110.93 369.9s1.31 2.27 1.6 2.06a32.84 32.84 0 0 1 4.29-.4 1.19 1.19 0 0 1 1.26 1.2c0 3.09-.2 11-1.92 14.16a8.72 8.72 0 0 1-6.91 4.79 1.57 1.57 0 0 1-1.61-1.19c-1.15-4.39-2-13.52-2.06-14.43a1.57 1.57 0 0 0-.82-1.32M112.53 372s-5.79 1.46-7 4.13M110.93 218.9s1.32-2.27 1.61-2.06a34.78 34.78 0 0 0 4.28.4 1.2 1.2 0 0 0 1.27-1.2c0-3.09-.2-11-1.93-14.16a8.72 8.72 0 0 0-6.9-4.79 1.55 1.55 0 0 0-1.61 1.19c-1.16 4.39-2 13.52-2.07 14.43a1.55 1.55 0 0 1-.82 1.32M112.54 216.84s-5.79-1.46-7-4.13"
                              className="cls-8"></path>
                        <path data-v-7a1233dc=""
                              d="M559.5,655.6H614c3.2,0,5.8,2.6,5.8,5.8v14.1c0,3.2-2.6,5.8-5.8,5.8h-54.5c-3.2,0-5.8-2.6-5.8-5.8v-14.1 C553.7,658.2,556.3,655.6,559.5,655.6z"
                              className="cls-bottom"></path>
                        <path data-v-7a1233dc=""
                              d="M560.2,802.2h54.5c3.2,0,5.8,2.6,5.8,5.8v14.1c0,3.2-2.6,5.8-5.8,5.8h-54.5c-3.2,0-5.8-2.6-5.8-5.8V808 C554.4,804.8,557,802.2,560.2,802.2z"
                              className="cls-bottom"></path>
                        <path data-v-7a1233dc=""
                              d="M285.8,803.3h54.5c3.2,0,5.8,2.6,5.8,5.8v14.1c0,3.2-2.6,5.8-5.8,5.8h-54.5c-3.2,0-5.8-2.6-5.8-5.8v-14.1 C280,805.9,282.6,803.3,285.8,803.3z"
                              className="cls-bottom"></path>
                        <path data-v-7a1233dc=""
                              d="M285.8,655.6h54.5c3.2,0,5.8,2.6,5.8,5.8v14.1c0,3.2-2.6,5.8-5.8,5.8h-54.5c-3.2,0-5.8-2.6-5.8-5.8v-14.1 C280,658.2,282.6,655.6,285.8,655.6z"
                              className="cls-bottom"></path>
                        <path data-v-7a1233dc=""
                              d="M263.9,772.4c-20.6,0-38.7-1.8-39.1-1.9h-0.3v-60.3h0.3c0.5,0,54.4-2.9,72.5,0c16.1,2.5,23.7,11.4,24,27.8 c0.3,12.6-4.2,23.6-11.7,28.8c-0.7,0.5-1.5,0.9-2.4,1.1C295.6,771.4,279.1,772.4,263.9,772.4z M225.2,769.9 c4.3,0.4,55.5,5.2,81.9-2.6c0.8-0.2,1.5-0.6,2.2-1c7.3-5.1,11.6-15.9,11.4-28.3c-0.3-16.3-7.6-24.7-23.5-27.2 c-17.3-2.8-67.6-0.2-72,0L225.2,769.9z"
                              className="cls-bottom"></path>
                        <path data-v-7a1233dc=""
                              d="M375.6,804c-2.5,0-4.9-0.5-7.1-1.5l-39.5-18c-3.7-1.7-7.6-2.7-11.6-3l-9-0.7c-3.3-0.3-6.7-0.1-9.9,0.5l-97.1,17.3 c-1.9,0.3-3.7-0.9-4-2.8c-0.3-1.9,0.9-3.7,2.8-4l0,0l97.1-17.3c3.9-0.7,7.8-0.9,11.7-0.6l9,0.7c4.8,0.4,9.5,1.6,13.9,3.6l39.5,17.9 c4,1.8,8.7,0.9,11.6-2.3c-0.4-4-0.4-13.6-0.1-39.2c0.1-8.6,7.2-15.6,15.8-15.6h153.1c1.7,0,3.3-0.4,4.8-1.1l35.7-18.2 c3.3-1.7,6.8-2.8,10.4-3.3c6.5-0.9,15.8-1.5,23.1,1.1c11.4,4,21,14.4,21.4,14.8c1.4,1.3,1.4,3.5,0.1,4.9c-1.3,1.4-3.5,1.4-4.9,0.1 c-0.1-0.1-0.2-0.2-0.3-0.3s-8.8-9.5-18.6-13c-6.3-2.2-15.2-1.4-19.9-0.8c-2.9,0.4-5.7,1.3-8.2,2.6L559.8,744 c-2.5,1.3-5.2,1.9-7.9,1.9H398.8c-4.8,0-8.8,3.9-8.9,8.7c-0.4,29.8-0.2,36.9,0,38.7c0.8,2-0.5,3.6-1,4.3 C385.7,801.6,380.8,804,375.6,804z"
                              className="cls-bottom"></path>
                        <path data-v-7a1233dc=""
                              d="M258.2,796.1c-2.3,0-4.4-1.6-4.9-3.9l-2.4-10.2c-0.6-2.8,1.1-5.5,3.9-6.1h0.1l36.6-7.3c2.7-0.5,5.3,1.2,6,3.8l2.4,10 c0.7,2.7-1,5.5-3.8,6.2H296l-36.5,7.6C258.9,796.1,258.5,796.1,258.2,796.1z M292.4,770c-0.2,0-0.5,0-0.7,0.1l-36.6,7.3 c-1.8,0.4-3,2.1-2.7,4v0.1l2.4,10.2c0.4,1.8,2.2,2.9,4,2.5l36.5-7.5c0.9-0.2,1.7-0.7,2.2-1.5s0.7-1.7,0.4-2.6l-2.4-10 C295.3,771.1,293.9,770,292.4,770z"
                              className="cls-bottom"></path>
                        <path data-v-7a1233dc=""
                              d="M457.8,752.8h-42.6c-5.1,0.2-9.4-3.8-9.6-9c-0.2-5.1,3.8-9.4,9-9.6c0.2,0,0.4,0,0.6,0h42.6c5.1-0.2,9.4,3.8,9.6,9 c0.2,5.1-3.8,9.4-9,9.6C458.2,752.8,458,752.8,457.8,752.8z M415.2,736c-4.2-0.2-7.7,3.1-7.8,7.3s3.1,7.7,7.3,7.8c0.2,0,0.4,0,0.6,0 h42.6c4.2,0.2,7.7-3.1,7.8-7.3s-3.1-7.7-7.3-7.8c-0.2,0-0.4,0-0.6,0H415.2z"
                              className="cls-bottom"></path>
                        <rect data-v-7a1233dc="" x="406.8" y="743.2" width="59.5" height="0.7"></rect>
                        <rect data-v-7a1233dc="" x="252.4" y="782.4"
                              transform="matrix(0.9844 -0.1757 0.1757 0.9844 -133.2482 60.5294)"
                              width="45.6" height="0.7"></rect>
                        <path data-v-7a1233dc=""
                              d="M326.8,778.3c-0.1-5-0.8-55.4,0-72c-3.2-8.6-24-27.3-25.6-28.1c0.1,0,0.2,0.1,0.3,0.1v-1.7c1.3,0,11,9.4,12.9,11.2 c5.5,5.5,12.4,13.1,14.2,18.1l0.1,0.2v0.2c-0.9,17.2,0,71.9,0,72.5v0.4"
                              className="cls-bottom"></path>
                        <path data-v-7a1233dc=""
                              d="M596.6,716.4c-1.8,0-3.5-0.8-4.6-2.2l-5.2-6.8c-1.3-1.7-3.5-2.3-5.5-1.4l-6.8,2.9c-1,0.4-2.1,0.8-3.2,1l-13.4,3.2 c-3,0.7-6-0.8-7.2-3.7c-0.8-2.1,0.2-4.4,2.2-5.3c0.3-0.1,0.6-0.2,0.8-0.2l11-2c3.7-0.7,7.2-2.1,10.3-4.1l16.1-10.4v-8.2h15.1v11.1 c0,1.8-0.8,3.5-2.2,4.6l-2.7,2.2c-0.6,0.5-0.8,1.5-0.3,2.1l3.6,5.1c1.8,2.6,1.3,6.2-1.3,8.1l-3.5,2.6 C599.1,716,597.9,716.4,596.6,716.4z M583.2,703.9c2,0,3.8,0.9,5,2.5l5.2,6.8c1.3,1.7,3.8,2.1,5.5,0.8l3.5-2.6 c1.8-1.3,2.2-3.9,0.9-5.7l-3.6-5.1c-1-1.4-0.7-3.4,0.6-4.5l2.7-2.2c1-0.8,1.6-2,1.5-3.2v-9.4h-11.6v7.3L576,699.5 c-3.3,2.2-7,3.6-10.9,4.3l-11,2c-0.7,0.1-1.3,0.6-1.6,1.2c-0.3,0.6-0.4,1.3-0.1,2c0.8,2,3,3.1,5.1,2.6l13.4-3.2c1-0.2,2-0.6,2.9-1 l6.8-2.9C581.5,704.1,582.3,703.9,583.2,703.9z"
                              className="cls-bottom"></path>
                        <path data-v-7a1233dc=""
                              d="M605.2,804.3h-15.1v-8.1L574,785.7c-3.1-2-6.6-3.4-10.3-4.1l-11-2c-2.2-0.4-3.7-2.5-3.3-4.8c0-0.3,0.1-0.5,0.2-0.7 c1.1-2.8,4.2-4.4,7.2-3.7l13.4,3.2c1.1,0.3,2.1,0.6,3.2,1l6.8,2.9c1.9,0.8,4.2,0.2,5.5-1.4l5.2-6.8c1.9-2.5,5.4-3,8-1.1l3.5,2.6 c2.5,1.9,3.1,5.5,1.3,8.1L600,784c-0.5,0.7-0.4,1.6,0.3,2.1l2.7,2.2c1.4,1.1,2.2,2.8,2.2,4.6L605.2,804.3z M591.9,802.5h11.6v-9.4 c0-1.3-0.6-2.5-1.5-3.2l-2.7-2.2c-1.4-1.1-1.6-3.1-0.6-4.5l3.6-5.1c1.3-1.8,0.9-4.4-0.9-5.7l-3.6-2.7c-1.8-1.3-4.2-0.9-5.5,0.8 l-5.2,6.8c-1.8,2.3-4.9,3.1-7.5,2l-6.8-2.9c-0.9-0.4-1.9-0.7-2.9-1l-13.4-3.2c-2.1-0.5-4.3,0.6-5.1,2.6c-0.5,1.2,0.1,2.5,1.3,3 c0.1,0.1,0.3,0.1,0.5,0.1l11,2c3.9,0.7,7.6,2.2,10.9,4.3l16.9,10.9L591.9,802.5z"
                              className="cls-bottom"></path>
                        <path data-v-7a1233dc=""
                              d="M328.6,782.7v-0.2c-0.4-8.9-0.4-27.9-0.3-44.2h-1.7c-0.1,16.2-0.1,35.1,0.3,44.1c-3.2,8.6-24,27.3-25.6,28.1 c0.1,0,0.2-0.1,0.3-0.1v1.7c1.3,0,11-9.4,12.9-11.2C320,795.4,326.9,787.8,328.6,782.7L328.6,782.7z"
                              className="cls-bottom"></path>
                        <path data-v-7a1233dc=""
                              d="M345.2,793.3h-8.1c-0.8,0-1.5-0.6-1.5-1.4c0-0.3,0.1-0.6,0.2-0.8l6.1-9.6c0.7-1.1,1.1-2.4,1.1-3.7v-39.5l0,0V711 c0-1.3-0.4-2.6-1.1-3.7l-6.1-9.6c-0.4-0.7-0.2-1.6,0.4-2c0.2-0.2,0.5-0.2,0.8-0.2h8.1c3.3,0,5.9-2.7,5.9-5.9c0-3.3-2.7-5.9-5.9-5.9 h-13.7l-6.7-6.1l-1.2,1.3l7.2,6.5h14.4c2.3,0,4.2,1.9,4.2,4.2s-1.9,4.2-4.2,4.2l0,0H337c-1.8,0-3.2,1.4-3.2,3.2 c0,0.6,0.2,1.2,0.5,1.7l6.1,9.6c0.5,0.8,0.8,1.8,0.8,2.8v64.3l-6.5,6.7l1.2,1.2l5.3-5.4c0,1-0.3,1.9-0.8,2.7l-6.1,9.6 c-0.9,1.5-0.5,3.5,1,4.4c0.5,0.3,1.1,0.5,1.7,0.5h8.1c2.3,0,4.2,1.9,4.2,4.2s-1.9,4.2-4.2,4.2l0,0h-14.4l-7.2,6.5l1.2,1.3l6.7-6.1 h13.7c3.3,0,5.9-2.7,5.9-5.9C351.2,796,348.5,793.3,345.2,793.3L345.2,793.3z"
                              className="cls-bottom"></path>
                        <path data-v-7a1233dc=""
                              d="M551.16 208.32c-29.5-.5-213.45-.19-226.7.36s-69.5 6.3-78.25 7.8M346.88 357.21s-21.53-63.32-.7-126.62M551.89 378.08c-29.5.5-213.3 1.53-226.55 1.06-13.73-.5-66.09-4.69-73.39-6.35"
                              className="cls-bottom cls-9"></path>
                        <path data-v-7a1233dc="" d="M536.89 366.41s34.83-58.25-.17-144.29L532 215l2.93-6.82"
                              className="cls-bottom cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M829.55 205l11.17 1.67s11.57 2.7 14.87 7.35c1.31 1.85 1 4.38 1.2 8.05.26 3.85.65 8 .65 8l16.5.14s4.16.25 4.35-2.5c.14-1.9.3-14 0-18.81-.07-1.22-.17-2.77-2.26-3.26l-4.08-.2z"
                              className="cls-bottom"></path>
                        <path data-v-7a1233dc=""
                              d="M856.47 360l17 .24a4.39 4.39 0 0 1 3.11 1.3 1.1 1.1 0 0 1 .34.8v17.88a4.13 4.13 0 0 1-.37 1.7 2 2 0 0 1-1.82 1.17l-41.31.32s-26.5 1.67-29.84 1.17l-1.5-1.17s-12.5-.67-16-3.17a57.11 57.11 0 0 0-8-8.5c-2-1.5-24.33-10.16-30-14.5-5.16-2.83-9.33-5.5-10.5-7.5s-4.16-27.33-4.33-53.66v-3c.17-26.34 3.17-51.67 4.33-53.67s5.34-4.67 10.5-7.5c5.67-4.33 28-13 30-14.5a56.62 56.62 0 0 0 8-8.5c3.5-2.5 16-3.17 16-3.17l1.5-1.16c3.34-.5 29.84 1.16 29.84 1.16l40 .63a2.59 2.59 0 0 1 1.91.88 3.51 3.51 0 0 1 .87 2.31v16.76a4.22 4.22 0 0 1-1.61 3.31.48.48 0 0 1-.32.11l-16.64-.18"
                              className="cls-8"></path>
                        <path data-v-7a1233dc=""
                              d="M775.63 370.3s-1.41 2.43-1.72 2.21a35.37 35.37 0 0 0-4.57-.43 1.27 1.27 0 0 0-1.35 1.28c0 3.29.21 11.71 2 15.11a9.26 9.26 0 0 0 7.37 5.11 1.65 1.65 0 0 0 1.72-1.26c1.24-4.69 2.13-14.44 2.21-15.41a1.66 1.66 0 0 1 .88-1.41l2.19-.43s-.79-6.14-8.73-4.77zM773.54 372.37s6.57 2.54 7.74 5.21"
                              className="cls-8"></path>
                        <path data-v-7a1233dc="" d="M777.41 393.58l1.77-18.24" className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M775.63 219.34s-1.41-2.43-1.72-2.21a34.12 34.12 0 0 1-4.57.42 1.26 1.26 0 0 1-1.35-1.27c0-3.29.21-11.72 2-15.12a9.31 9.31 0 0 1 7.37-5.11 1.67 1.67 0 0 1 1.72 1.27c1.24 4.69 2.13 14.44 2.21 15.41a1.68 1.68 0 0 0 .88 1.41l2.19.43s-.79 6.14-8.73 4.77zM773.54 217.27s6.57-2.54 7.74-5.21"
                              className="cls-8"></path>
                        <path data-v-7a1233dc=""
                              d="M777.41 196.05l1.77 18.25M779.11 293c-.71-26.56 1.55-57.67 2.06-65.28a4.42 4.42 0 0 0-1-3.17 2.38 2.38 0 0 0-2.26-.81c-8.44 1.63-32.08 13.83-32.08 13.83s-1.26 34.43-1.73 57.12c-.55 26.22 2.4 59.22 2.4 59.22s23 11.93 31.41 13.57a2.39 2.39 0 0 0 2.26-.82 4.38 4.38 0 0 0 1-3.16c-.51-7.61-2.78-38-2.08-64.59zM746.42 353.84l-8.84-4.06M737.58 239.45l8.89-2.13M820.52 334.88c15.09-2.92 26.62-8.12 28.62-10.46M781.17 363.39c-.08.07 15.75-13.85 20.47-18.38M819.68 256.83c15.15 1.94 27.19 8.34 29.19 10.67M780.12 224.52s18.25 19 22.46 23"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M848.87 291.84l.26-56.79c0-1 9.11-5.79 8.47-5.45M856.47 360l-6.21-1.46a2.72 2.72 0 0 1-2.1-2.67l.71-61.86"
                              className="cls-8"></path>
                        <path data-v-7a1233dc=""
                              d="M848.14 324.42l9.46 2.34M848.87 267.26l8.77-3.05M801.64 372.37l-15.56 7.91M842.94 291.88v-18.92a5.57 5.57 0 0 0-1-3.38 6.6 6.6 0 0 0-1.32-1.32c-1.17-.84-19.94-8-21.94-8.42-2.18-.47-6.76 1.68-6.67 5.9 0 1.7.13 11.64 0 25.64M812.23 295.38c0 12.27.07 23.17.09 29.6 0 2.84 3.33 6.44 5.9 5.92.1 0 1-.16 1-.16 1.11 0 17.31-6.4 21.28-8a3.81 3.81 0 0 0 2-2.22 4.59 4.59 0 0 0 .34-1.46c.06-1 .07-12.32.05-24.42"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M841.33 291.51v-16.69a5.58 5.58 0 0 0-.46-2.78 3.67 3.67 0 0 0-2.22-1.59l-18.11-7.2a2.25 2.25 0 0 0-1.79-.19c-1 .34-1.83 1.68-2 3.33-.08.88-.26 9.61-.14 24.75M816.64 294.79c-.13 14.9.09 28.51.17 29.38a3.69 3.69 0 0 0 2 3.27 2.32 2.32 0 0 0 1.8-.19l18.08-7.08a3.66 3.66 0 0 0 2.21-1.56 5.41 5.41 0 0 0 .46-2.73v-20.09"
                              className="cls-10"></path>
                        <path data-v-7a1233dc="" d="M836.38 291.3v-21.49M836.38 320.91v-26.86" className="cls-8"></path>
                        <path data-v-7a1233dc="" d="M822.97 291.3v-27.08M822.97 326.31l-.04-31.69"
                              className="cls-10"></path>
                        <path data-v-7a1233dc=""
                              d="M848.14 356.27v10.83a6.18 6.18 0 0 1-2.44 4.9 10.46 10.46 0 0 1-5.56 2.23c-4.75.29-29.17 2.16-33.25 1.08s-5.25-3-5.25-3V345a1.66 1.66 0 0 1 1-1.53c2.58-1.07 9.36-3.84 12.23-4.43a2.23 2.23 0 0 1 1.57 2.2l-.44 34.37"
                              className="cls-8"></path>
                        <path data-v-7a1233dc=""
                              d="M821.18 361.37l3.77-1a1.56 1.56 0 0 0 1.15-1.51v-6.75a1.19 1.19 0 0 0-1.52-1.14L820.8 352a1.47 1.47 0 0 0-1 1.41v6.83a1.14 1.14 0 0 0 1.38 1.13z"
                              className="cls-9"></path>
                        <circle data-v-7a1233dc="" cx="841.77" cy="358.01" r="5.47" className="cls-9"></circle>
                        <path data-v-7a1233dc="" d="M801.33 218.57l-15.25-9.62" className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M849.13 235.05l.39-11.63a7 7 0 0 0-2.8-5.16c-1.39-1.06-4.41-1.35-6.66-1.49-4.75-.29-29.17-2.17-33.25-1.08s-5.25 3-5.25 3V246a1.66 1.66 0 0 0 1 1.53c2.57 1.07 9.36 3.83 12.22 4.43a2.25 2.25 0 0 0 1.58-2.21l-.45-34.36"
                              className="cls-8"></path>
                        <path data-v-7a1233dc=""
                              d="M821.1 229.66l3.77 1a1.55 1.55 0 0 1 1.15 1.5v6.75a1.2 1.2 0 0 1-1.53 1.15l-3.77-1.06a1.46 1.46 0 0 1-1.05-1.4v-6.84a1.13 1.13 0 0 1 1.43-1.1zM812.22 327.54l-32.26 16.37M812.12 264.21l-32.16-12.23"
                              className="cls-9"></path>
                        <circle data-v-7a1233dc="" cx="785.27" cy="323.01" r="1.28" className="cls-11"></circle>
                        <circle data-v-7a1233dc="" cx="785.55" cy="268.23" r="1.28" className="cls-11"></circle>
                        <path data-v-7a1233dc="" d="M803.72 205c0 .09 2.42 9.23 2.53 10.71M803.58 384.62s4.44-7 5.27-9"
                              className="cls-9"></path>
                        <path data-v-7a1233dc="" d="M836.38 330.26v44.24" className="cls-9"></path>
                        <circle data-v-7a1233dc="" cx="842.23" cy="233.58" r="5.47" className="cls-9"></circle>
                        <path data-v-7a1233dc="" d="M836.83 216.83v44.25" className="cls-9"></path>
                        <circle data-v-7a1233dc="" cx="579.64" cy="553.68" r="32.45"></circle>
                        <circle data-v-7a1233dc="" cx="579.64" cy="552.81" r="23.72" className="cls-8"></circle>
                        <circle data-v-7a1233dc="" cx="579.63" cy="553.8" r="22.73" className="cls-12"></circle>
                        <circle data-v-7a1233dc="" cx="579.61" cy="553.65" r="10.89" className="cls-9"></circle>
                        <circle data-v-7a1233dc="" cx="311.05" cy="552.81" r="32.45"></circle>
                        <path data-v-7a1233dc=""
                              d="M273.3 560.57s-51.87-7-57.62-9.5c-8-4-7-25.75-7-25.75l6-7.12V511l2-1.75 1.87-15.25-1.87-.38c.25-3.75 7.5-12.12 7.5-12.12s31.25-3.63 35.75-3.5c29.37-15 80.25-30.13 108.37-32.25 66.13-3.5 93.5 1.25 112.25 7.75 23.25 9.37 65.5 35.12 65.5 35.12h7.38s60.26 8.35 87.67 16.53a42.59 42.59 0 0 1 16.25 8.85 31.94 31.94 0 0 1 5.51 6.27s.11 4.28 2.36 6"
                              className="cls-8"></path>
                        <path data-v-7a1233dc=""
                              d="M631 524.41c1.42 2.91 6.91 19.83 6.91 19.83l35.42 1.08s-3.5-13.33-8.08-19c-4.37-.5-34.25-1.91-34.25-1.91z"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M631 524.41s-7.25-9.92-7.75-11.17 1.08-1.67 1.08-1.67l26.85 2a2.54 2.54 0 0 1 1.91.87c2 2.32 7.53 6.61 8.39 8a3.85 3.85 0 0 1 0 3.62M670.81 537.49l-35.55-.66"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M659.15 530.42l1.39 3.51a1 1 0 0 1-.89 1.31h-3.87A1.91 1.91 0 0 1 654 534l-1.34-3.55a.84.84 0 0 1 .79-1.15h4.09a1.74 1.74 0 0 1 1.61 1.12z"
                              className="cls-10"></path>
                        <path data-v-7a1233dc=""
                              d="M674.18 555.78l-29.92-1a5.58 5.58 0 0 1-3.2-1.09 3.06 3.06 0 0 1-1.09-1.37c-.5-1.5-2.09-8.08-2.09-8.08"
                              className="cls-9"></path>
                        <ellipse data-v-7a1233dc="" cx="652.78" cy="549.85" rx="4.98" ry="5.03"
                                 className="cls-10"></ellipse>
                        <path data-v-7a1233dc="" d="M651.15 513.57S646.78 502 538.61 490.2" className="cls-10"></path>
                        <path data-v-7a1233dc=""
                              d="M546.05 488.57s-1.44 2.09-10.94 1.54a2.07 2.07 0 0 1-1-.34c-9.41-6.22-46-28.31-58.34-33.24a.69.69 0 0 1 0-1.26 38.79 38.79 0 0 1 4.83-1.82"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M259.93 478s.5 1.12 8.87 2c14.5-6.75 63-24.25 63-24.25 98.39-19.5 144 .83 144 .83"
                              className="cls-10"></path>
                        <path data-v-7a1233dc=""
                              d="M527.88 493s-23.88-22.72-54.16-34c-24.31-8.24-45.72-8.5-62-8.88-16-.37-77.41.61-122.69 32.37a3 3 0 0 0-1.13 3.26z"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M287.93 485.75l-56.25-1.05M250 492.41s241.32 6.41 280.57 7.91c43 1.5 92.67 12.92 92.67 12.92"
                              className="cls-10"></path>
                        <path data-v-7a1233dc=""
                              d="M218.55 494l31.45-1.59s-7.18 19.41-8.3 24.54c-.79 3.59-.38 7.5 11.12 9.12s28.61 3.36 28.61 3.36"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M514.67 479.18c2.35 2 8.7 8.41 1.21 15.89a111.26 111.26 0 0 1-10.76-3.81 3.22 3.22 0 0 1-1.9-2.94v-4.87s.93-3.94 9.76-4.8a2.31 2.31 0 0 1 1.69.53z"
                              className="cls-8"></path>
                        <path data-v-7a1233dc=""
                              d="M518.89 490.64s-10-3.47-12.84-2.89l-2.83.57M418.57 489.69s1.61-39.36 0-39.4l10.79.29 2.55 39.49"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M421.09 556.77l3.6-8.63s-.29-.9 0-5.07c1.13-18.34-3.6-46.32-3.6-46.32l-2.52-7.06"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M447.3 504.89h-15.37l2-1.94h11.62l1.75 1.94zM365.59 502.12h-15.38l2-1.94h11.63l1.75 1.94z"
                              className="cls-10"></path>
                        <path data-v-7a1233dc=""
                              d="M278.83 533.54l-70.15-8.22M228.5 553.86s-7.28-6-9.95-10.79c-2.17-3.16-8.61-1.94-8.61-1.94M223.22 493.81s-3.84 17.1-4.67 19.6-3.87 4.79-3.87 4.79"
                              className="cls-8"></path>
                        <path data-v-7a1233dc="" d="M244.72 508.41l-25.04.7" className="cls-9"></path>
                        <rect data-v-7a1233dc="" x="539.63" y="504.07" width="6.42" height="2.58" rx="1.29" ry="1.29"
                              transform="rotate(-180 542.845 505.365)" className="cls-9"></rect>
                        <circle data-v-7a1233dc="" cx="311.05" cy="552.81" r="22.73" className="cls-12"></circle>
                        <circle data-v-7a1233dc="" cx="311.05" cy="552.32" r="10.89" className="cls-9"></circle>
                        <path data-v-7a1233dc=""
                              d="M617.7 554.88l23.36-1.19M363.26 547.07l180 2.82M345.55 487.69c2-7.74 5.7-17.83 11-32.51M414.61 450.58l-2.5 38.58M524.11 571.62l-172.75-1.33"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M533.15 106.29l3.2 2.31S610 91.76 626.57 86.79c-4.86 0 6.66-12.83 6.66-12.83L667.48 72s3.23-1.55 4.64-7.88l4.1.25c1.43-1.25 1.1-6.1 1.11-9.61a1.76 1.76 0 0 0-1.76-1.76l-1.5-8.36h.52a1.84 1.84 0 0 0 1.85-1.84v-.25a3 3 0 0 0-1.38-2.54 56.24 56.24 0 0 0-10.87-5.13c-17 .11-38.47-1.26-42.16-1.51a1.11 1.11 0 0 0-.94.42 4.33 4.33 0 0 0-1 2.46c.05 8.54.84 33.38-21.8 43.16a39.41 39.41 0 0 1-26.87 1.34s-27.42-7.84-27.1-47.26h-18l7.75 14.87s4 38.43 2.76 49.57c-3.46 6.63-3.68 8.36-3.68 8.36z"
                              className="dmg__section-19 cls-3"></path>
                        <path data-v-7a1233dc=""
                              d="M500.33 127.21c11.6-7.85 24.91-17.27 29.82-21.92l2.67-7.29s3.5-31.61-3.28-49.29c-3.28-5.68-7.65-15.26-7.65-15.26l-96.46.18 6.76 16.57-3.76 50.84-2.81 7.6v39.11s43.59.54 74.71-20.54z"
                              className="dmg__section-18 cls-4"></path>
                        <path data-v-7a1233dc=""
                              d="M354.51 34.67l66.32-.18L427 50.22s-1.08 44.34-3.61 51.4l-2.52 7.06v39.4s-42.3 0-62-4.9l-7.14-1.56-13.18-30.94-2.43-6.12 29.48-53.27z"
                              className="dmg__section-17 cls-5"></path>
                        <path data-v-7a1233dc=""
                              d="M360.21 51.4l-29.54 52.3 16.76 36.89-15.81 1.24S276.37 122.76 271.5 118l-37.56-4.37-8.46-9.11-4-17.15s-1.53-4.57-3-5.9-7.54-7.81-7.54-9.68-1.27-19.7 7-24.54c10.29-2.33 57.63-9.5 57.63-9.5s-3.95 44.66 38.93 45.29c28.37-.4 38.85-23.45 36.91-46.7l5.44 8.13z"
                              className="dmg__section-16 cls-2"></path>
                        <circle data-v-7a1233dc="" cx="581.91" cy="44.68" r="32.45"></circle>
                        <circle data-v-7a1233dc="" cx="581.91" cy="45.55" r="23.72" className="cls-8"></circle>
                        <circle data-v-7a1233dc="" cx="581.89" cy="44.56" r="22.73" className="cls-12"></circle>
                        <circle data-v-7a1233dc="" cx="581.87" cy="44.72" r="10.89" className="cls-9"></circle>
                        <circle data-v-7a1233dc="" cx="313.32" cy="45.55" r="32.45"></circle>
                        <path data-v-7a1233dc=""
                              d="M275.57 37.79s-51.88 7-57.63 9.5c-8 4-7 25.75-7 25.75l6 7.13v7.25l2 1.75 1.88 15.25-1.88.37c.25 3.75 7.5 12.13 7.5 12.13s31.25 3.62 35.75 3.5c29.38 15 80.25 30.12 108.38 32.25 66.12 3.5 93.5-1.25 112.25-7.75 23.25-9.38 65.5-35.13 65.5-35.13h7.37s60.26-8.35 87.68-16.53a42.52 42.52 0 0 0 16.25-8.92 31.87 31.87 0 0 0 5.5-6.26s.11-4.29 2.36-6"
                              className="cls-8"></path>
                        <path data-v-7a1233dc=""
                              d="M633.23 74c1.43-2.92 6.92-19.83 6.92-19.83L675.57 53s-3.5 13.34-8.09 19c-4.33.54-34.25 2-34.25 2z"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M633.23 74s-7.25 9.92-7.75 11.17 1.09 1.66 1.09 1.66l26.85-2a2.55 2.55 0 0 0 1.91-.86c2-2.32 7.52-6.62 8.39-8a3.85 3.85 0 0 0 0-3.62M673.08 60.88l-35.56.66"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M661.42 67.94l1.38-3.5a1 1 0 0 0-.89-1.31H658a1.88 1.88 0 0 0-1.77 1.22l-1.35 3.54a.85.85 0 0 0 .79 1.15h4.09a1.74 1.74 0 0 0 1.66-1.1z"
                              className="cls-10"></path>
                        <path data-v-7a1233dc=""
                              d="M676.44 42.59l-29.91 1a5.56 5.56 0 0 0-3.2 1.09 3.13 3.13 0 0 0-1.1 1.37c-.5 1.5-2.08 8.09-2.08 8.09"
                              className="cls-9"></path>
                        <ellipse data-v-7a1233dc="" cx="655.04" cy="48.52" rx="4.98" ry="5.03" className="cls-10"></ellipse>
                        <path data-v-7a1233dc="" d="M653.42 84.79S649 96.33 540.88 108.17" className="cls-10"></path>
                        <path data-v-7a1233dc=""
                              d="M548.32 109.79s-1.45-2.08-10.94-1.53a2.1 2.1 0 0 0-1 .34c-9.41 6.22-46 28.31-58.33 33.24a.68.68 0 0 0 0 1.25 37.89 37.89 0 0 0 4.83 1.83"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M262.19 120.42s.5-1.13 8.88-2c14.5 6.75 63 24.25 63 24.25 98.39 19.5 144-.83 144-.83"
                              className="cls-10"></path>
                        <path data-v-7a1233dc=""
                              d="M530.15 105.39s-23.93 22.7-54.16 34c-24.31 8.24-45.73 8.49-62 8.87-16 .38-77.4-.6-122.69-32.36a3 3 0 0 1-1.12-3.26z"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M290.2 112.62l-56.26 1.05M252.25 106s241.32-6.46 280.57-8c43-1.5 92.66-12.91 92.66-12.91"
                              className="cls-10"></path>
                        <path data-v-7a1233dc=""
                              d="M220.82 104.42l31.43 1.58s-7.18-19.41-8.31-24.53c-.79-3.59-.37-7.5 11.13-9.13s28.61-3.35 28.61-3.35"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M516.93 119.19c2.35-2 8.71-8.41 1.22-15.9a111.9 111.9 0 0 0-10.77 3.82 3.21 3.21 0 0 0-1.9 2.94v4.87s.94 3.94 9.76 4.8a2.29 2.29 0 0 0 1.69-.53z"
                              className="cls-8"></path>
                        <path data-v-7a1233dc=""
                              d="M521.15 107.73s-10 3.46-12.83 2.89l-2.84-.57M420.83 108.68s1.62 39.36 0 39.4l10.79-.29 2.55-39.5"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M423.35 41.59l3.65 8.63s-.29.91 0 5.08c1.12 18.34-3.61 46.32-3.61 46.32l-2.52 7.06"
                              className="cls-9"></path>
                        <path data-v-7a1233dc=""
                              d="M449.57 93.48h-15.38l2 1.94h11.63l1.75-1.94zM367.85 96.25h-15.37l2 1.94h11.62l1.75-1.94z"
                              className="cls-10"></path>
                        <path data-v-7a1233dc=""
                              d="M281.09 64.83l-70.15 8.21M230.77 44.51s-7.29 6-10 10.79c-2.17 3.16-8.62 1.93-8.62 1.93M225.48 104.56s-3.83-17.1-4.66-19.6-3.88-4.79-3.88-4.79"
                              className="cls-8"></path>
                        <path data-v-7a1233dc="" d="M246.98 89.96l-25.04-.7" className="cls-9"></path>
                        <rect data-v-7a1233dc="" x="541.9" y="91.71" width="6.42" height="2.58" rx="1.29" ry="1.29"
                              transform="rotate(180 545.105 93)" className="cls-9"></rect>
                        <circle data-v-7a1233dc="" cx="313.32" cy="45.55" r="22.73" className="cls-12"></circle>
                        <circle data-v-7a1233dc="" cx="313.32" cy="46.04" r="10.89" className="cls-9"></circle>
                        <path data-v-7a1233dc=""
                              d="M619.96 43.48l23.37 1.19M365.53 51.29l179.99-2.81M347.82 110.68c2 7.74 5.69 17.82 11 32.5M416.87 147.79l-2.5-38.58M526.37 32.74l-172.75 1.34M275.76 357.3s-20.83-62.32 0-125.62M246.37 216c-3.66 4.94-23.83 30.23-21.2 86.94 0 0 4.3 60.86 21.26 69"
                              className="cls-9"></path>
                    </svg>

                </div>
    );
}

export default PassangerCarSVG
