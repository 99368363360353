const Legend = () => {

return (
    <ul data-v-dbf4370a="" data-v-9df6b412="" className="legend">
            <li data-v-dbf4370a="" className="legend__item legend__item--default">
                <div data-v-527ae0f9="" data-v-dbf4370a="" className="base-indicator legend__icon"></div>
                <span data-v-dbf4370a="" className="legend__value text-body-20">нет повреждений</span></li>
            <li data-v-dbf4370a="" className="legend__item legend__item--warning">
                <div data-v-527ae0f9="" data-v-dbf4370a=""
                     className="base-indicator legend__icon base-indicator--warning"></div>
                <span data-v-dbf4370a="" className="legend__value text-body-20">повреждения не изменившие геометрию элементов и/или характеристики ТС</span>
            </li>
            <li data-v-dbf4370a="" className="legend__item legend__item--danger">
                <div data-v-527ae0f9="" data-v-dbf4370a=""
                     className="base-indicator legend__icon base-indicator--danger"></div>
                <span data-v-dbf4370a="" className="legend__value text-body-20">повреждения изменившие геометрию элементов и/или характеристики ТС</span>
            </li>
            <li data-v-dbf4370a="" className="legend__item legend__item--unknown">
                <div data-v-527ae0f9="" data-v-dbf4370a=""
                     className="base-indicator legend__icon base-indicator--unknown"></div>
                <span data-v-dbf4370a=""
                      className="legend__value text-body-20">другие повреждения различного характера</span>
            </li>
    </ul>
    );
}

export default Legend
