const AutobusSVG = ({ SvgData }) => {

    return (
        <div data-v-9df6b412="" className={SvgData}>
            <svg data-v-60ac9ed0="" xmlns="http://www.w3.org/2000/svg" viewBox="14 82 1165 980.88">
                <rect data-v-60ac9ed0="" x="324" y="912.76" width="549.91" height="150" rx="12" ry="12"
                      class="dmg__section-43 cls-1"></rect>
                <rect data-v-60ac9ed0="" x="495.23" y="365.55" width="276.27" height="208.88"
                      class="dmg__section-41 cls-2"></rect>
                <path data-v-60ac9ed0="" d="M776.5,365.55h85.42a12,12,0,0,1,12,12V562.42a12,12,0,0,1-12,12H776.5Z"
                      class="dmg__section-40 cls-3"></path>
                <path data-v-60ac9ed0=""
                      d="M926,470.77v72.09a21,21,0,0,0,5.7,14.38l2.77,2.93a20.05,20.05,0,0,0,14.23,6.3l12.37.17.12,17.06a6.08,6.08,0,0,0,6.06,6h43.12v-8.43a7.17,7.17,0,0,0-7.17-7.19H970.83v5.21h-4.08V566.86h1.89l145.69,3a19.48,19.48,0,0,0,12.5-4.23l2.36-1.88V470.48Z"
                      class="dmg__section-30 cls-1"></path>
                <path data-v-60ac9ed0=""
                      d="M1127.54,468.17H926V396.12a21,21,0,0,1,5.7-14.38l2.77-2.93a20.11,20.11,0,0,1,14.23-6.32l12.37-.18.12-16.74a6.4,6.4,0,0,1,6.38-6.34h42.8v9.7a5.92,5.92,0,0,1-5.91,5.92H970.83v-5.21h-4.08v12.48h1.89l145.69-3a19.55,19.55,0,0,1,12.5,4.24l2.36,1.87v92.91Z"
                      class="dmg__section-39 cls-3"></path>
                <path data-v-60ac9ed0=""
                      d="M1129.17,563.69h29.19c2.25,0,4.08-2.49,4.08-5.57V535.68c0-3.08-1.83-5.58-4.08-5.58h-29.19Z"
                      class="cls-9"></path>
                <path data-v-60ac9ed0=""
                      d="M1129.17,408.71h29.19c2.25,0,4.08-2.5,4.08-5.58V380.69c0-3.08-1.83-5.58-4.08-5.58h-29.19Z"
                      class="cls-9"></path>
                <path data-v-60ac9ed0="" d="M926,511.22l-2-1.14a5.27,5.27,0,0,1-2.66-4.59V433.77a5.87,5.87,0,0,1,2.7-5l2-1.26"
                      class="cls-4"></path>
                <path data-v-60ac9ed0=""
                      d="M957.74,558.68l74.31,1.35a10.66,10.66,0,0,0,10.84-10.47h0V386.13a10.64,10.64,0,0,0-10.64-10.64h-.36L957.59,378a7.47,7.47,0,0,0-7.2,6.85c-1.29,15.86-4.37,56.71-4.1,84.53.26,27.59,3,66.77,4.14,82.37A7.47,7.47,0,0,0,957.74,558.68Z"
                      class="cls-5"></path>
                <line data-v-60ac9ed0="" x1="1028.94" y1="560.03" x2="1028.94" y2="375.59" class="cls-6"></line>
                <line data-v-60ac9ed0="" x1="1036.01" y1="546.9" x2="1021.94" y2="487.22" class="cls-6"></line>
                <line data-v-60ac9ed0="" x1="1036.01" y1="389.23" x2="1021.94" y2="445.22" class="cls-6"></line>
                <path data-v-60ac9ed0=""
                      d="M937.28,547.89v-6a2,2,0,0,1,2-2h0a2,2,0,0,1,2,2v6a2,2,0,0,1-2,2h0A2,2,0,0,1,937.28,547.89Z"
                      class="cls-6"></path>
                <path data-v-60ac9ed0=""
                      d="M937.28,397.23v-6a2,2,0,0,1,2-2h0a2,2,0,0,1,2,2v6a2,2,0,0,1-2,2h0A2,2,0,0,1,937.28,397.23Z"
                      class="cls-6"></path>
                <path data-v-60ac9ed0=""
                      d="M1070.64,568.91s1.72-9.72,3.45-31.44c1.61-20.19,8-22.73,15.34-24.25a2,2,0,0,1,2.38,1.52,1.93,1.93,0,0,1,0,.48c-.66,15.23-1.51,36.09-3.56,38.3-5.07,5.46-11.71,6.47-16.42,6.47"
                      class="cls-6"></path>
                <path data-v-60ac9ed0=""
                      d="M1073.82,512.62l16.17-7.41a4.61,4.61,0,0,0,2.7-4.22c-.47-24.91-1.67-54.77,0-65.18a4,4,0,0,0-2.37-3.61l-16-6.95a4.55,4.55,0,0,0-4.45.47h0a4.22,4.22,0,0,0-1.78,3.45v78.05a10.18,10.18,0,0,0,.32,2.26,4.27,4.27,0,0,0,4.75,3.33A2.94,2.94,0,0,0,1073.82,512.62Z"
                      class="cls-6"></path>
                <path data-v-60ac9ed0=""
                      d="M1077.24,553.35h0a2.79,2.79,0,0,1,2.79-2.79h0a2.79,2.79,0,0,1,2.79,2.79h0a2.79,2.79,0,0,1-2.79,2.79h0a2.79,2.79,0,0,1-2.79-2.79Z"
                      class="cls-6"></path>
                <path data-v-60ac9ed0="" d="M1073.57,543.42s6.63-16.33,18-21.58" class="cls-6"></path>
                <path data-v-60ac9ed0="" d="M1103.78,569.59c-.5,0-3.17-2.2-3.17-5v-94" class="cls-6"></path>
                <path data-v-60ac9ed0=""
                      d="M1105.11,525.86v22.69a7.9,7.9,0,0,0,2.16,5.43h0a8,8,0,0,0,4.68,2.41c2,.27,4.54.78,5.49,1.64"
                      class="cls-6"></path>
                <path data-v-60ac9ed0=""
                      d="M1109.78,547h0a3.57,3.57,0,0,1,3.57-3.57h0a3.57,3.57,0,0,1,3.57,3.57h0a3.57,3.57,0,0,1-3.57,3.57h0A3.57,3.57,0,0,1,1109.78,547Z"
                      class="cls-6"></path>
                <path data-v-60ac9ed0=""
                      d="M1070.76,369.75s1.71,9.72,3.44,31.44c1.61,20.19,8,22.73,15.34,24.25a2,2,0,0,0,2.38-1.52,1.93,1.93,0,0,0,0-.48c-.65-15.23-1.51-36.09-3.56-38.29-5.07-5.47-11.71-6.48-16.42-6.48"
                      class="cls-6"></path>
                <path data-v-60ac9ed0=""
                      d="M1083,385.31h0a2.79,2.79,0,0,1-2.79,2.79h0a2.79,2.79,0,0,1-2.79-2.79h0a2.77,2.77,0,0,1,2.77-2.79h0a2.77,2.77,0,0,1,2.79,2.77Z"
                      class="cls-6"></path>
                <path data-v-60ac9ed0="" d="M1073.71,395.25s6.62,16.32,18,21.57" class="cls-6"></path>
                <path data-v-60ac9ed0="" d="M1103.91,369.08c-.5,0-3.17,2.19-3.17,5v94" class="cls-6"></path>
                <path data-v-60ac9ed0=""
                      d="M1105.24,412.77V390.08a7.9,7.9,0,0,1,2.16-5.43h0a8,8,0,0,1,4.69-2.41c2-.27,4.53-.77,5.49-1.63"
                      class="cls-6"></path>
                <path data-v-60ac9ed0=""
                      d="M1117.05,391.67h0a3.57,3.57,0,0,1-3.57,3.57h0a3.57,3.57,0,0,1-3.57-3.57h0a3.57,3.57,0,0,1,3.57-3.57h0A3.57,3.57,0,0,1,1117.05,391.67Z"
                      class="cls-6"></path>
                <path data-v-60ac9ed0=""
                      d="M1118.74,468.17v-45a2.42,2.42,0,0,0-2.4-2.42,2.29,2.29,0,0,0-1,.23c-3.81,1.79-9.39,4.52-9.39,4.52v87.7s5.58,2.73,9.39,4.52a2.4,2.4,0,0,0,3.2-1.14h0a2.17,2.17,0,0,0,.24-1Z"
                      class="cls-5"></path>
                <path data-v-60ac9ed0=""
                      d="M262.06,469.55V397.5a20.89,20.89,0,0,0-5.7-14.38l-2.77-2.93a20.06,20.06,0,0,0-14.19-6.32l-12.4-.18-.12-17.06a6.08,6.08,0,0,0-6.06-6H177.73v8.43a7.19,7.19,0,0,0,7.17,7.19h32.27V361h4.08v12.48h-1.87l-145.72-3a19.54,19.54,0,0,0-12.5,4.23l-2.36,1.88v93.22Z"
                      class="dmg__section-35 cls-3"></path>
                <path data-v-60ac9ed0=""
                      d="M60.46,472.15h201.6v72.07a20.89,20.89,0,0,1-5.7,14.38l-2.77,2.93a20.07,20.07,0,0,1-14.19,6.33L227,568l-.12,16.74a6.39,6.39,0,0,1-6.38,6.34H177.7v-9.7a5.92,5.92,0,0,1,5.92-5.92h33.52v5.21h4.08v-12.5h-1.87l-145.72,3A19.5,19.5,0,0,1,61.16,567l-2.36-1.88v-93Z"
                      class="dmg__section-34 cls-1"></path>
                <path data-v-60ac9ed0="" d="M262.06,427.33l2,1.14a5.22,5.22,0,0,1,2.66,4.58v71.72a5.87,5.87,0,0,1-2.7,5L262,511"
                      class="cls-4"></path>
                <path data-v-60ac9ed0=""
                      d="M58.88,376.87H29.66c-2.26,0-4.09,2.49-4.09,5.57v22.43c0,3.08,1.83,5.58,4.09,5.58H58.88Z"
                      class="cls-9"></path>
                <path data-v-60ac9ed0=""
                      d="M58.88,531.86H29.66c-2.26,0-4.09,2.5-4.09,5.58v22.42c0,3.08,1.83,5.58,4.09,5.58H58.88Z"
                      class="cls-9"></path>
                <path data-v-60ac9ed0=""
                      d="M225.88,384.94H189a37.2,37.2,0,0,0-19.27,5.38L167,392a13.59,13.59,0,0,0-6.56,11.63v129A26,26,0,0,0,168.06,551h0a23,23,0,0,0,16.24,6.73h41.58a7.42,7.42,0,0,0,7.42-7.42h0v-158A7.43,7.43,0,0,0,225.88,384.94Z"
                      class="cls-5"></path>
                <path data-v-60ac9ed0=""
                      d="M251.68,394v6a2,2,0,0,1-2,2h0a2,2,0,0,1-2-2v-6a2,2,0,0,1,2-2h0A2,2,0,0,1,251.68,394Z"
                      class="cls-6"></path>
                <path data-v-60ac9ed0=""
                      d="M251.68,544.69v6a2,2,0,0,1-2,2h0a2,2,0,0,1-2-2v-6a2,2,0,0,1,2-2h0A2,2,0,0,1,251.68,544.69Z"
                      class="cls-6"></path>
                <path data-v-60ac9ed0="" d="M128.55,371.65s14.08,8.29,17.58,13.29h-52s2.53-10.58,7.53-13.29"
                      class="cls-6"></path>
                <line data-v-60ac9ed0="" x1="182.16" y1="385.59" x2="108.99" y2="384.94" class="cls-6"></line>
                <path data-v-60ac9ed0="" d="M128.66,569.86s14.08-7.19,17.58-11.52h-52s2.73,11.1,9.5,12.52" class="cls-6"></path>
                <line data-v-60ac9ed0="" x1="182.26" y1="557.78" x2="109.1" y2="558.33" class="cls-6"></line>
                <line data-v-60ac9ed0="" x1="146.13" y1="384.94" x2="146.23" y2="558.33" class="cls-5"></line>
                <line data-v-60ac9ed0="" x1="94.13" y1="384.94" x2="94.23" y2="558.33" class="cls-5"></line>
                <polyline data-v-60ac9ed0="" points="79.88 444.19 84.88 444.19 84.88 498.68 79.88 498.68"
                          class="cls-6"></polyline>
                <rect data-v-60ac9ed0="" x="97.88" y="466.68" width="5.5" height="9.25" class="cls-6"></rect>
                <circle data-v-60ac9ed0="" cx="79.09" cy="393.74" r="3.29" class="cls-6"></circle>
                <circle data-v-60ac9ed0="" cx="79.09" cy="550.98" r="3.29" class="cls-6"></circle>
                <circle data-v-60ac9ed0="" cx="106.13" cy="379.62" r="2.75" class="cls-6"></circle>
                <circle data-v-60ac9ed0="" cx="115.13" cy="379.62" r="2.75" class="cls-6"></circle>
                <circle data-v-60ac9ed0="" cx="106.13" cy="564.59" r="2.75" class="cls-6"></circle>
                <circle data-v-60ac9ed0="" cx="115.13" cy="564.59" r="2.75" class="cls-6"></circle>
                <line data-v-60ac9ed0="" x1="1073.82" y1="512.62" x2="1073.82" y2="425.25" class="cls-6"></line>
                <line data-v-60ac9ed0="" x1="1077.24" y1="511.01" x2="1077.24" y2="426.65" class="cls-6"></line>
                <path data-v-60ac9ed0=""
                      d="M793.17,309.13C841,307.38,838.93,288,838.93,288s12.48-18,18-31.33S874.27,176,874.27,164h-3.51s1-12.58.65-15.08l2.21-.38-.13-34-4-3.75H815.14l-.82,15.67a31.15,31.15,0,0,1-4.86,15.17c-3.36,5.23-8.44,11.19-14.73,12.54-.26,16.74-1,131.49-1,131.49Z"
                      class="dmg__section-38 cls-2"></path>
                <path data-v-60ac9ed0=""
                      d="M840.65,285.46l-4.84-3.59,9.41-62.6a11.57,11.57,0,0,1,7.43-9l16.07-6.1,1.78-10.47L855,199.6a21.17,21.17,0,0,0-13.61,16.8c-3,22.36-9.12,67.62-8.73,68.75.52,1.5,6.24,2.83,6.24,2.83"
                      class="cls-6"></path>
                <path data-v-60ac9ed0="" d="M837.11,113.64v9.78a12.3,12.3,0,0,0,3.23,8.27l.87,1a16.18,16.18,0,0,0,12,5.24h17.32"
                      class="cls-6"></path>
                <rect data-v-60ac9ed0="" x="851.59" y="120.39" width="17.25" height="9.75" rx="4.87" ry="4.87"
                      class="cls-6"></rect>
                <path data-v-60ac9ed0=""
                      d="M871.44,148.88H860.8c-8.87,0-16.08,7.25-15.23,15.74a5.58,5.58,0,0,0,.12,1c6.76-.5,25.1-1.67,25.1-1.67"
                      class="cls-6"></path>
                <path data-v-60ac9ed0=""
                      d="M494.66,309.13h169v5.5H743v-5.5h45.79l1.56-153.25h-31s-21.32-3.75-23.92-21.5-1.8-23.62-1.8-23.62H497.27Z"
                      class="dmg__section-37 cls-1"></path>
                <path data-v-60ac9ed0=""
                      d="M341.86,309.13h74.55v5.5h71.33v-5.5h3.12l2.08-168.83s-9,14-20.45,16H442.9a14.16,14.16,0,0,1-4.42-.72c-5.77-1.93-19.82-8.09-21.47-22.61-2.08-18.33-1.35-22.21-1.35-22.21H314.05V290.57a16,16,0,0,0,9.4,14.47h0A43.38,43.38,0,0,0,341.86,309.13Z"
                      class="dmg__section-36 cls-3"></path>
                <ellipse data-v-60ac9ed0="" cx="456.75" cy="118.64" rx="37.44" ry="36"></ellipse>
                <ellipse data-v-60ac9ed0="" cx="456.75" cy="119.05" rx="19.6" ry="18.84" class="cls-3"></ellipse>
                <ellipse data-v-60ac9ed0="" cx="456.75" cy="119.05" rx="9.8" ry="9.42" class="cls-6"></ellipse>
                <ellipse data-v-60ac9ed0="" cx="775.01" cy="116.97" rx="37.44" ry="36"></ellipse>
                <ellipse data-v-60ac9ed0="" cx="775.01" cy="117.38" rx="19.6" ry="18.84" class="cls-1"></ellipse>
                <ellipse data-v-60ac9ed0="" cx="775.01" cy="116.97" rx="9.8" ry="9.42" class="cls-6"></ellipse>
                <polyline data-v-60ac9ed0="" points="845.68 165.63 826.11 169.97 412.51 171.63" class="cls-6"></polyline>
                <path data-v-60ac9ed0="" d="M782.77,286c-.35-1,0-77.67,0-77.67H331.38V286Z" class="cls-6"></path>
                <rect data-v-60ac9ed0="" x="624.56" y="114.64" width="64.95" height="169" class="cls-6"></rect>
                <rect data-v-60ac9ed0="" x="504.38" y="114.64" width="112.33" height="54" class="cls-6"></rect>
                <rect data-v-60ac9ed0="" x="554.64" y="123.3" width="12.83" height="6.83" class="cls-6"></rect>
                <rect data-v-60ac9ed0="" x="708.92" y="126.72" width="9.36" height="2.46" class="cls-6"></rect>
                <path data-v-60ac9ed0="" d="M808,307.87s15-89.88,16.71-99.55c.69-30.33,3.12-97.53,3.12-97.53"
                      class="cls-6"></path>
                <rect data-v-60ac9ed0="" x="633" y="193.68" width="11.09" height="7.61" class="cls-6"></rect>
                <line data-v-60ac9ed0="" x1="768.9" y1="309.13" x2="768.9" y2="285.96" class="cls-6"></line>
                <rect data-v-60ac9ed0="" x="357.38" y="133.97" width="55.12" height="124" class="cls-6"></rect>
                <rect data-v-60ac9ed0="" x="357.38" y="260.63" width="55.12" height="23" class="cls-6"></rect>
                <polyline data-v-60ac9ed0="" points="331.38 204.29 331.38 114.64 412.51 114.64" class="cls-6"></polyline>
                <path data-v-60ac9ed0=""
                      d="M314.05,193.68l6.63-9.87a9.86,9.86,0,0,0,1.69-5.51V151a8.36,8.36,0,0,0-4.57-7.38l-3.75-2"
                      class="cls-6"></path>
                <rect data-v-60ac9ed0="" x="341.09" y="126.72" width="9.01" height="3.42" class="cls-7"></rect>
                <rect data-v-60ac9ed0="" x="366.4" y="193.68" width="11.09" height="7.61" class="cls-6"></rect>
                <rect data-v-60ac9ed0="" x="512.69" y="255.96" width="109.21" height="27.67" class="cls-6"></rect>
                <rect data-v-60ac9ed0="" x="512.69" y="210.45" width="109.21" height="43.51" class="cls-6"></rect>
                <rect data-v-60ac9ed0="" x="691.93" y="256.05" width="84.59" height="27.67" class="cls-6"></rect>
                <rect data-v-60ac9ed0="" x="691.93" y="210.54" width="84.59" height="43.51" class="cls-6"></rect>
                <path data-v-60ac9ed0=""
                      d="M803.12,633.23C851,635,848.88,654.4,848.88,654.4s12.48,18,18,31.33,17.33,80.67,17.33,92.67h-3.5s1,12.58.65,15.08l2.28.38-.13,34-4,3.74H825.09l-.82-15.6a31.15,31.15,0,0,0-4.86-15.17c-3.36-5.23-8.44-11.19-14.73-12.54-.26-16.75-1-131.5-1-131.5Z"
                      class="dmg__section-31 cls-2"></path>
                <path data-v-60ac9ed0=""
                      d="M850.58,656.86l-4.82,3.58,9.41,62.6a11.59,11.59,0,0,0,7.43,9l16,6.17,1.78,10.47L865,742.79A21.18,21.18,0,0,1,851.35,726c-3-22.36-9.12-67.62-8.73-68.75.52-1.5,6.24-2.83,6.24-2.83"
                      class="cls-6"></path>
                <path data-v-60ac9ed0=""
                      d="M847.06,828.73V819a12.19,12.19,0,0,1,3.23-8.27l.87-1a16.11,16.11,0,0,1,12-5.24h17.28"
                      class="cls-6"></path>
                <rect data-v-60ac9ed0="" x="861.54" y="812.23" width="17.25" height="9.75" rx="4.87" ry="4.87"
                      class="cls-6"></rect>
                <path data-v-60ac9ed0=""
                      d="M881.39,793.48H870.75c-8.87,0-16.08-7.25-15.23-15.74a5.58,5.58,0,0,1,.12-1c6.76.5,25.09,1.67,25.09,1.67"
                      class="cls-6"></path>
                <path data-v-60ac9ed0=""
                      d="M504.66,633.23h169v-5.5H753v5.5h45.76l1.56,153.25h-31S748,790.23,745.39,808s-1.82,23.62-1.82,23.62H507.22Z"
                      class="dmg__section-32 cls-1"></path>
                <path data-v-60ac9ed0=""
                      d="M351.81,633.23h74.55v-5.5h71.3v5.5h3.12l2.08,168.84s-9-14-20.45-16H452.85a13.56,13.56,0,0,0-4.42.72c-5.77,1.93-19.77,8.07-21.47,22.61-2.08,18.33-1.38,22.2-1.38,22.2H324V651.79a16,16,0,0,1,9.39-14.47h0A43.43,43.43,0,0,1,351.81,633.23Z"
                      class="dmg__section-33 cls-3"></path>
                <ellipse data-v-60ac9ed0="" cx="466.7" cy="823.73" rx="37.44" ry="36"></ellipse>
                <ellipse data-v-60ac9ed0="" cx="466.7" cy="823.33" rx="19.6" ry="18.84" class="cls-3"></ellipse>
                <ellipse data-v-60ac9ed0="" cx="466.7" cy="823.33" rx="9.8" ry="9.42" class="cls-6"></ellipse>
                <ellipse data-v-60ac9ed0="" cx="784.96" cy="825.4" rx="37.44" ry="36"></ellipse>
                <ellipse data-v-60ac9ed0="" cx="784.96" cy="824.99" rx="19.6" ry="18.84" class="cls-1"></ellipse>
                <ellipse data-v-60ac9ed0="" cx="784.96" cy="825.4" rx="9.8" ry="9.42" class="cls-6"></ellipse>
                <polyline data-v-60ac9ed0="" points="855.63 776.73 836.06 772.4 422.46 770.73" class="cls-6"></polyline>
                <path data-v-60ac9ed0="" d="M792.72,656.4c-.35,1,0,77.67,0,77.67H341.33V656.4Z" class="cls-6"></path>
                <rect data-v-60ac9ed0="" x="634.5" y="658.73" width="64.95" height="169" class="cls-6"></rect>
                <rect data-v-60ac9ed0="" x="514.32" y="773.73" width="112.33" height="54" class="cls-6"></rect>
                <rect data-v-60ac9ed0="" x="564.59" y="812.23" width="12.83" height="6.83" class="cls-6"></rect>
                <rect data-v-60ac9ed0="" x="718.87" y="813.19" width="9.36" height="2.46" class="cls-6"></rect>
                <path data-v-60ac9ed0="" d="M818,634.52s15,89.88,16.71,99.55c.69,30.33,3.12,97.53,3.12,97.53"
                      class="cls-6"></path>
                <rect data-v-60ac9ed0="" x="642.95" y="741.07" width="11.09" height="7.61" class="cls-6"></rect>
                <line data-v-60ac9ed0="" x1="778.85" y1="633.23" x2="778.85" y2="656.4" class="cls-6"></line>
                <rect data-v-60ac9ed0="" x="367.33" y="684.4" width="55.12" height="124" class="cls-6"></rect>
                <rect data-v-60ac9ed0="" x="367.33" y="658.73" width="55.12" height="23" class="cls-6"></rect>
                <polyline data-v-60ac9ed0="" points="341.33 738.07 341.33 827.73 422.46 827.73" class="cls-6"></polyline>
                <path data-v-60ac9ed0="" d="M324,748.68l6.63,9.87a10,10,0,0,1,1.69,5.51V791.4a8.37,8.37,0,0,1-4.57,7.38l-3.75,2"
                      class="cls-6"></path>
                <rect data-v-60ac9ed0="" x="351.04" y="812.23" width="9.01" height="3.42" class="cls-7"></rect>
                <rect data-v-60ac9ed0="" x="376.35" y="741.07" width="11.09" height="7.61" class="cls-6"></rect>
                <rect data-v-60ac9ed0="" x="522.64" y="658.73" width="109.21" height="27.67" class="cls-6"></rect>
                <rect data-v-60ac9ed0="" x="522.64" y="688.4" width="109.21" height="43.51" class="cls-6"></rect>
                <rect data-v-60ac9ed0="" x="701.88" y="658.65" width="84.59" height="27.67" class="cls-6"></rect>
                <rect data-v-60ac9ed0="" x="701.88" y="688.31" width="84.59" height="43.51" class="cls-6"></rect>
                <line data-v-60ac9ed0="" x1="863.32" y1="736.5" x2="847.04" y2="716.61" class="cls-7"></line>
                <path data-v-60ac9ed0="" d="M490.23,574.42H320.06a12,12,0,0,1-12-12V377.55a12,12,0,0,1,12-12H490.23Z"
                      class="dmg__section-42 cls-1"></path>
                <rect data-v-60ac9ed0="" x="659.72" y="431.85" width="78.41" height="78.33" class="cls-4"></rect>
                <rect data-v-60ac9ed0="" x="404.16" y="430.53" width="78.41" height="78.33" class="cls-4"></rect>
                <path data-v-60ac9ed0=""
                      d="M828.72,384.66c.82,21.46,4.34,121,.3,165.92a5.75,5.75,0,0,0,4.1,6c12.13,3.67,28.83,7.23,32.14,1.22,7.34-13.33,3-177.32,3-177.32s-.43-8.67-11.71-6.67c-7.84,1.39-18.29,4.07-23.94,5.58A5.15,5.15,0,0,0,828.72,384.66Z"
                      class="cls-6"></path>
                <rect data-v-60ac9ed0="" x="503.26" y="401.19" width="234.88" height="7.33" class="cls-9"></rect>
                <rect data-v-60ac9ed0="" x="502.48" y="534.52" width="234.88" height="7.33" class="cls-9"></rect>
                <rect data-v-60ac9ed0="" x="668.39" y="455.18" width="5.34" height="28.67" class="cls-9"></rect>
                <rect data-v-60ac9ed0="" x="410.15" y="451.85" width="4.69" height="36" class="cls-9"></rect>
                <rect data-v-60ac9ed0="" x="331.4" y="371.06" width="2.67" height="198.8" class="cls-9"></rect>
                <rect data-v-60ac9ed0="" x="441.78" y="925.4" width="58.73" height="17.32" rx="2.83" ry="2.83"
                      class="cls-9"></rect>
                <rect data-v-60ac9ed0="" x="441.78" y="945.35" width="58.73" height="17.32" rx="2.83" ry="2.83"
                      class="cls-9"></rect>
                <rect data-v-60ac9ed0="" x="441.78" y="1012.36" width="58.73" height="17.32" rx="2.83" ry="2.83"
                      class="cls-9"></rect>
                <rect data-v-60ac9ed0="" x="441.78" y="1032.31" width="58.73" height="17.32" rx="2.83" ry="2.83"
                      class="cls-9"></rect>
                <rect data-v-60ac9ed0="" x="760.18" y="928.4" width="58.73" height="17.32" rx="2.83" ry="2.83"
                      class="cls-9"></rect>
                <rect data-v-60ac9ed0="" x="761.31" y="1032.68" width="58.73" height="17.32" rx="2.83" ry="2.83"
                      class="cls-9"></rect>
                <line data-v-60ac9ed0="" x1="785.41" y1="945.72" x2="784.28" y2="1038.7" class="cls-4"></line>
                <line data-v-60ac9ed0="" x1="794.19" y1="945.72" x2="793.06" y2="1038.7" class="cls-4"></line>
                <rect data-v-60ac9ed0="" x="344.09" y="971.33" width="61.74" height="30.49" class="cls-4"></rect>
                <rect data-v-60ac9ed0="" x="408.47" y="977.52" width="23.72" height="14.76" class="cls-4"></rect>
                <line data-v-60ac9ed0="" x1="432.19" y1="982.62" x2="460.23" y2="982.62" class="cls-4"></line>
                <line data-v-60ac9ed0="" x1="432.19" y1="986.95" x2="460.23" y2="986.95" class="cls-4"></line>
                <line data-v-60ac9ed0="" x1="460.23" y1="962.67" x2="460.23" y2="1012.36" class="cls-4"></line>
                <line data-v-60ac9ed0="" x1="476.04" y1="960.79" x2="476.04" y2="1012.36" class="cls-4"></line>
                <rect data-v-60ac9ed0="" x="339.27" y="1023.43" width="33.68" height="9.24" rx="4" ry="4"
                      transform="translate(-622.64 552.92) rotate(-45)" class="cls-5"></rect>
                <path data-v-60ac9ed0=""
                      d="M369.22,1015.09l3.55-3.56a7.46,7.46,0,0,1,5.28-2.18h32.3a3.76,3.76,0,0,0,3.76-3.76h0V993.41"
                      class="cls-8"></path>
                <path data-v-60ac9ed0="" d="M336,1049.63c.11-.17,2.48-2.59,8.3-8.45" class="cls-8"></path>
            </svg>
        </div>
    )
}

export default AutobusSVG